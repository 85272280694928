import * as React from 'react';
import {Button, SvgIcon} from "@mui/material";


export default function Doctor(props){
    return(


            <SvgIcon {...props}>
               <svg version="1.1" id="Слой_1" x="0px" y="0px"
                    viewBox="0 0 278 278" enableBackground="new 0 0 278 278">
<g>
	<g id="XMLID_1_">
		<circle fill="#FFFFFF" cx="139" cy="139" r="138.5"/>
		<path fill="#AA8C8C" d="M139,1c36.8611145,0,71.5159607,14.3545074,97.580719,40.4192657
			C262.6455078,67.4840393,277,102.1388855,277,139s-14.3544922,71.5159607-40.419281,97.580719
			C210.5159607,262.6455078,175.8611145,277,139,277s-71.5159607-14.3544922-97.5807343-40.419281
			C15.3545074,210.5159607,1,175.8611145,1,139s14.3545074-71.5159607,40.4192657-97.5807343S102.1388855,1,139,1 M139,0
			C62.2324219,0,0,62.2324219,0,139s62.2324219,139,139,139s139-62.2324219,139-139S215.7675781,0,139,0L139,0z"/>
	</g>
	<path fill="#78C2A4" d="M138.1058197,157.6541443c7.2696533,3.1136169,14.6004028,6.0846252,21.7783813,9.3951874
		c3.9420929,1.8165588,6.0506592,5.2561493,6.0744476,9.6566315c0.0678864,12.5631409,0.040741,25.1262817,0,37.6894073
		c0,4.1628113-2.5601807,7.2594604-6.7331543,8.3595886c-19.3200684,0.1018677-38.5688095,0.1018677-57.8990784,0
		c4.580452-1.4430542,7.4699707-5.7416992,6.1559372-10.1218109c-1.7928009-6.030304-3.9692688-11.9485626-5.9997406-18.0535583
		c-1.5313492-4.5566864-2.7570953-9.1031799-4.6415634-13.3508759c-1.6977158-3.7961121-4.970932-5.8809052-9.3136902-6.0812378
		c0-5.5990906,0.0169678-11.0385895,0.1120529-16.4033813c4.3054199,6.1118011,8.542923,12.1624756,12.7498779,18.2233429
		c2.9370651,4.2375183,7.6329498,6.0744476,12.5631332,4.7943573c2.8759308-0.7436066,4.9403687-2.6246796,6.6075439-4.9913025
		c4.2476959-6.0269012,8.4886017-12.0640106,12.7465057-17.9415131c0.0305481,8.4274902,0.0441284,16.6953888,0,25.0617523
		c-0.7435913,0.502533-1.4634399,0.8488617-2.0848083,1.3276215c-3.3513031,2.5771332-4.0541611,5.5787048-3.0185547,9.4766846
		c1.1816101,4.4412231,7.8095093,7.9656982,12.7973938,4.1967621c3.762146-2.8385925,4.8419037-8.1490631,1.9014435-11.7720032
		c-1.0661774-1.3106384-2.5975189-2.2477875-3.8945618-3.5142822
		C138.044693,174.8520508,138.0684814,166.2547913,138.1058197,157.6541443z"/>
	<path fill="#95D6A4" d="M115.8758774,49c2.4175491,0.3395424,4.6823044,0.6349487,6.9300842,1.018631
		c6.9844284,1.2019882,13.8024673,2.8827324,19.5441666,7.3749008c2.5737305,2.0101013,4.0745239,4.5091476,3.9862366,7.9589157
		c-0.1392212,4.7536163-0.0373383,9.480072-0.2173004,14.3796921c-3.802887,0.1595917-7.4292297,0.1561966-11.0589447,0
		c-0.2241058-3.5414429-2.4922333-5.6126633-6.0540771-5.6160583c-9.8467789,0-19.6935577-0.1629791-29.5199738,0.057724
		c-5.4972153,0.1256332-9.3442612-3.7146149-9.2661514-9.2729492c0.0543213-3.8436432,0.0271683-7.6906776,0-11.5445061
		c0-0.5772247-0.2546539-1.1544495-0.39048-1.7350693c4.2646713-0.7843475,8.5497208-1.6026497,12.885704-2.5126266
		C107.1020432,49,111.4108658,49,115.8758774,49z"/>
	<path fill="#78C2A4" d="M91.9278183,222.8466187c-4.753624,0-9.5072403,0-14.3898849-0.1052551
		c1.2597122-0.8658295,1.8369217-2.0678253,1.3921204-3.5210724s-1.6434021-2.0712128-3.1543579-2.0746002
		c-0.7334061,0-1.4634323-0.0203857-2.353035,0c-0.835289,0-1.5109787,0-2.1866684,0
		c-3.7349854,0-4.624588-1.2597198-3.4361877-4.8520966c1.6603775-5.0252533,3.3581009-10.0369263,5.0048828-15.065567
		c0.2546539-0.7843475,0.3021927-1.6332092,0.5432587-2.5296173c1.2664948-3.2901764,2.444725-6.5022736,3.602562-9.7211456
		c0.7266235-2.0202942,1.9082336-3.6874542,4.1594162-3.9251404c2.2301636-0.2511292,4.4794388-0.2852478,6.7161942-0.1018524
		c2.4820633,0.2207031,3.9760666,1.7860107,4.6823044,4.223938c0.8013153,2.7570953,1.7758102,5.4598541,2.7163544,8.1762085
		c0.1765671,0.5093231,0.529686,0.9575195,0.7877426,1.5652924c0.1116638,0.7025604,0.2704697,1.3967896,0.4753723,2.0780182
		c1.7384644,5.2289734,3.3954391,10.48172,5.2731323,15.6665649c0.9269485,2.587326-0.3395462,4.872467-3.1713562,4.5023651
		c-0.9472961-0.0665131-1.8980789-0.0665131-2.8453827,0c-0.9914627,0.0169678-1.9557724-0.2444763-2.6586304,0.0713043
		c-0.9914703,0.4447937-2.1662979,1.1544495-2.5601578,2.0576324c-0.305603,0.6994629,0.4346237,1.881073,0.7707672,2.8250122
		C91.3947296,222.3882294,91.7105026,222.5987701,91.9278183,222.8466187z"/>
	<path fill="#78C2A4" d="M60.5097809,222.8466187c-0.4991188-0.1765594-0.8284874-0.3768768-1.1782074-0.5262909
		c-3.5957832-1.5483093-5.4564781-4.2476959-5.4564781-8.1864014c0-12.2779236,0-24.5569611,0-36.8371429
		c0-5.1542816,2.4684944-8.7228851,7.0862846-10.7601624c6.8214569-3.0083466,13.7040024-5.8809052,20.7121964-8.6855316
		c0.1290283,5.9046631,0.1188354,11.6803131-0.040741,17.449173c-4.5566788,0.2852173-7.8095169,2.5024414-9.452919,6.488678
		c-1.7248917,4.2035675-2.9132996,8.6312256-4.3937073,13.0656738c-1.9829483,5.8605347-3.9828453,11.5920258-5.8028183,17.3812561
		c-0.7334061,2.3292694-0.3768883,4.6381836,1.0186348,6.7908936c1.2664909,1.95578,3.1984978,2.8963165,5.178051,3.8300629
		L60.5097809,222.8466187z"/>
	<path fill="#EAE9EB" d="M68.3192978,222.8466187c-2.139122-0.9337463-4.0745316-1.8742828-5.3376236-3.8300476
		c-1.3887444-2.1425323-1.7452621-4.4514313-1.0186348-6.7908936c1.8199539-5.7892151,3.8198738-11.5207214,5.9623985-17.3778687
		c1.9150314-0.1018524,3.6161499-0.098465,5.3172531-0.0950623c-0.0905685,0.827713-0.2391968,1.6480255-0.4448013,2.454895
		c-1.6467819,5.028656-3.3445053,10.040329-5.0048828,15.0655823c-1.1884003,3.5923767-0.2886047,4.8452911,3.4361877,4.8520813
		c0.7238922-0.0163116,1.4477692,0.032547,2.1628952,0.1460114c0.1561966,1.9455872,0.173172,3.7553558,0.1935425,5.5651245
		L68.3192978,222.8466187z"/>
	<path fill="#EAE9EB" d="M95.8835144,217.1253052c0.9051285-0.0476074,1.8123474-0.0385437,2.7163544,0.0271606
		c2.84198,0.3701019,4.0982971-1.9150391,3.1713486-4.5023651c-1.86409-5.1848297-3.5346451-10.4375916-5.2731323-15.6665802
		c-0.2252502-0.6713867-0.3320847-1.3768158-0.315773-2.0847931c1.8912659-0.1528015,3.6127548-0.1697845,5.3376236-0.1833649
		c2.0101013,5.9657898,4.1865921,11.8840485,5.9793701,17.9143524c1.3038559,4.380127-1.5754929,8.6787567-6.3155136,10.1218262
		c-1.677681,0.10466-3.3608093,0.0842285-5.0354462-0.0611115
		C95.9310303,220.7312622,95.9072647,218.9282837,95.8835144,217.1253052z"/>
	<path fill="#4F4553" d="M95.7408905,217.1456757c0.1663742,1.782608,0.1901398,3.5855865,0.2376862,5.544754
		c-1.2936249,0.133667-2.5943909,0.1858521-3.8945618,0.156189c-0.3734894-0.2478485-0.6790924-0.4583893-0.7911453-0.746994
		c-0.3395462-0.9439392-1.0763474-2.1255493-0.7707748-2.8250122c0.3938675-0.903183,1.5686951-1.6128387,2.5601578-2.0576324
		C93.7851105,216.9011993,94.7494278,217.1626587,95.7408905,217.1456757z"/>
	<path fill="#4C4250" d="M73.7486267,222.8466187c-0.1765671-1.8097687-0.1935425-3.6195374-0.1935425-5.5821075
		c0.7349167-0.1083069,1.4783173-0.1480865,2.2206116-0.1188354c1.5109787,0,2.7163544,0.6281586,3.1543579,2.0746002
		c0.4380035,1.4464569-0.1324234,2.6552429-1.5517197,3.5210876
		C76.186554,222.8466187,75.0456848,222.8466187,73.7486267,222.8466187z"/>
	<path fill="#D19B6F" d="M126.6597824,140.3373871c0.0509415,2.1323395,0.1901474,4.264679,0.1358261,6.3936157
		c-0.0823822,2.3718262,0.7323685,4.6875458,2.281723,6.4852905c-4.7536163,6.9199066-9.4800644,13.6564636-14.1793671,20.4066162
		c-2.7978363,4.0235901-7.1609955,4.0032196-9.9792099-0.0339661c-4.7377625-6.7908783-9.4913788-13.571579-14.260849-20.3420868
		c1.8539124-2.1085663,2.5805283-4.5566864,2.4039764-7.3511353c-0.077507-1.7959747-0.0162277-3.5952454,0.183342-5.3817749
		c5.5209961,2.5771332,11.2423096,3.935318,17.2148972,3.8504333
		C116.094017,144.2564697,121.631134,142.8800201,126.6597824,140.3373871z"/>
	<path fill="#79C2A4" d="M89.6936111,51.6620255c0.2974091,0.5287094,0.4801941,1.1140976,0.5364838,1.7180939
		c0.0339432,3.8470345,0.0611115,7.6940727,0,11.5445023c-0.0780869,5.558342,3.7689285,9.3985825,9.2661514,9.2729492
		c9.8467865-0.2207031,19.6935654-0.0679016,29.5199738-0.0577164c3.5618134,0,5.8299866,2.0746155,6.0676575,5.6228485
		c0,0.1460037,0,0.1731644-0.1493835,0.1629791c-16.83461,0-33.5096207,0-50.3374252,0
		c-3.779129-0.0271606-7.3952866-0.0271606-11.0046387-0.0271606c0-5.3138657-0.0814896-10.6311264,0-15.9416008
		c0.0373459-2.7604942,1.6977234-4.7536163,3.7112122-6.3936157C80.882431,54.5855026,85.1097641,52.8809891,89.6936111,51.6620255z
		"/>
	<path fill="#95D6A4" d="M90.5356903,153.2943878c4.8724518,6.7297668,9.6464462,13.5002899,14.3830872,20.2945557
		c2.8182144,4.0371857,7.181366,4.0575562,9.9792099,0.0339661c4.6993027-6.7501526,9.4291458-13.4867096,14.2235184-20.3013611
		c1.0992432,0.5150909,2.1532745,1.1216125,3.1509705,1.8131714c0.0590668,1.1763458,0.0590668,2.3549042,0,3.53125
		c-4.2397614,6.0371094-8.4818039,12.0708008-12.7261047,18.1011047c-1.6671524,2.3768158-3.7349854,4.2476959-6.6075363,4.9913025
		c-4.930191,1.2800751-9.6260757-0.5568542-12.5631409-4.7943573c-4.206955-6.0608673-8.444458-12.1013641-12.7702484-18.3761292
		c-0.1561966-1.2868805-0.2037201-2.3496552-0.2546616-3.4124146
		C88.3863754,154.5642853,89.4050064,153.9531097,90.5356903,153.2943878z"/>
	<path fill="#C3DDFE" d="M93.0177612,133.9471588c-1.7146988-1.5347443-3.504097-2.9913788-5.1237335-4.6177979
		c-4.9845047-5.0048828-7.8842087-11.0283966-8.5497208-18.0909195c-0.0950775-1.018631-0.2240906-2.0100937-0.3395462-3.3173447
		c0.0203781-3.9387131,0.0339508-7.5752335,0.2003403-11.2389069c1.95578,0,3.7519608,0.0305557,5.5345688,0.2037277
		c-0.4855423,5.4327087,1.1680374,9.6905899,5.7315063,12.6412277c-0.0707474,1.5941086-0.0367203,3.1911392,0.10186,4.7807846
		c0.6064911,6.161644,3.1763229,11.9654846,7.3307495,16.5561752c2.7971573,3.1352386,6.1217041,5.756485,9.8230133,7.7449951
		c-0.7062607-0.0305481-1.2698975-0.0611115-1.8267517-0.1324158c-4.3767319-0.5738373-8.403717-2.1425323-12.281311-4.3665466
		C93.4272919,134.0272064,93.2248764,133.9723053,93.0177612,133.9471588z"/>
	<path fill="#C38D67" d="M93.6730804,134.184845c3.8232651,2.1493073,7.8502579,3.7180176,12.223587,4.2918396
		c0.5568542,0.0713043,1.120491,0.1018677,1.9863281,0.1595917c6.7671127,0.4142456,12.7193298-1.2529144,18.3897018-4.3665314
		c0.1771088,0.6308441,0.3176804,1.2713776,0.4210281,1.9184265c0.0203781,1.2834778,0.0441437,2.5635529,0,3.9998322
		c-5.0158615,2.6529236-10.5872955,4.0838776-16.2607727,4.1763763c-5.9550552,0.0734558-11.839035-1.2990723-17.1469574-3.9998322
		c-0.1393738-1.5917969-0.182457-3.1905823-0.1290283-4.7875824
		C93.3878479,134.948822,93.5304565,134.5651398,93.6730804,134.184845z"/>
	<path fill="#6F4948" d="M73.5516891,80.0479202c3.6500931-0.1527939,7.2662506-0.1493988,11.0419769,0.0237656
		c-0.0373383,3.7859192-0.2376785,7.3952789-0.6009979,11.0182114c-1.6060333,0-3.0558929,0-4.4989624,0
		c-2.0882034,0-2.8284073,0.6587219-3.371666,2.7638931c-0.9643173,0-1.7758102-0.0203705-2.5907288-0.0237732
		C73.527916,89.2971039,73.5211182,84.7506104,73.5516891,80.0479202z"/>
	<path fill="#D09C73" d="M73.4362335,93.9047165c0.8990097-0.0686417,1.8027725-0.0413284,2.6959763,0.0814972
		c0.387085,1.7452545,1.4702377,2.4379196,2.930275,2.7163544c0,3.6365204-0.0271759,7.2730331-0.0645142,11.0521622
		c-1.6389313,0.1634064-3.289566,0.1725006-4.930191,0.0271606c-3.2228317-0.2974854-5.7996521-2.8063889-6.1831055-6.0201111
		c-0.4244308-2.8963165,1.191803-5.7281113,4.0745239-7.1304321C72.4243927,94.4038467,72.8827744,94.1899338,73.4362335,93.9047165
		z"/>
	<path fill="#4C404F" d="M87.2964249,155.0736084c0.1222305,1.1646271,0.1697769,2.2274017,0.2410889,3.4429779
		c0,5.5888977,0,11.0283966-0.1663742,16.6376648c-1.9693527,0.1595917-3.7859268,0.1629791-5.6058807,0.1663666
		c0-5.7722473,0.0237732-11.5444946,0-17.4865265c0-0.5466614,0.0611191-0.9303436,0.0814896-1.3208313
		c0.0645142-1.3887329,0.5330887-2.5228119,1.9863281-2.9608154
		C85.2863083,153.1144257,86.4441681,153.6814728,87.2964249,155.0736084z"/>
	<path fill="#D19B6F" d="M93.6255341,134.1101532c-0.096283,0.4498138-0.2373276,0.8888855-0.4210281,1.3106384
		c-0.0992661-0.4466553-0.1594925-0.9010925-0.1799698-1.3581848
		C93.2195282,133.9730377,93.4470367,133.9910278,93.6255341,134.1101532z"/>
	<path fill="#DBEAFE" d="M126.1810379,134.1916351c-5.5753098,3.1917114-11.5445023,4.8588715-18.1520462,4.4140778
		c-3.8403015-1.9073334-7.277832-4.5356293-10.125206-7.7416077c-4.1556625-4.5902405-6.7266998-10.3941269-7.3341446-16.5561752
		c-0.1570511-1.5810089-0.1502075-3.1740189,0.0203705-4.753624c1.5686874,0.3123856,2.8827286,0.8692322,4.2510986,1.0661697
		c7.6601181,1.0899429,12.0232468-2.2817383,12.4816513-9.5445862c1.8505173-2.1696854,3.23246-2.1221542,5.2357635,0.2342834
		c0.2105103,3.7825241,1.4464569,6.8418198,4.9539566,8.5429382c4.1084747,1.9897308,10.8144684,0.8963928,14.1148529-2.2341995
		c3.1645355-2.9947815,3.7825317-6.7671204,3.5957642-10.8925781c1.95578-0.0203781,3.7553711,0,5.5549316,0.142601
		c0.0271606,3.7859192,0.0577393,7.4258347,0.0882874,11.062355c-0.2920074,7.1575928-2.3123169,13.7413483-6.9640503,19.2589417
		c-2.037262,2.4243469-4.624588,4.3970947-7.110054,6.6686554
		C126.4968109,134.0252533,126.3406067,134.1067505,126.1810379,134.1916351z"/>
	<path fill="#4B404F" d="M132.3098297,158.8255768c0-1.2766876,0.0475311-2.3937836,0.0848846-3.6602936
		c0.7741699-1.5822754,1.9014282-2.4175568,3.5720062-1.921814c2.1017609,0.6213531,2.0101166,2.437912,2.0915985,4.2748566
		c0,8.7364807,0,17.3167572-0.193512,26.1007538c-1.9353943,0.1697693-3.7349854,0.1697693-5.4972076,0.1697693
		C132.3539734,175.5209503,132.3335724,167.2530518,132.3098297,158.8255768z"/>
	<path fill="#795453" d="M135.0770721,79.8985214c3.6127625,0,7.2390747,0,11.0249939,0
		c0.1867676,4.5397034,0.2105103,9.0862045,0.0712891,13.7922821c-0.8596802,0.1656418-1.7409058,0.1874466-2.6077118,0.0645142
		c-0.2954102-1.9421921-1.3989258-2.6891861-3.1781311-2.6552353c-1.5679932,0.0350266-3.1366882-0.0148468-4.6992798-0.1493988
		c-0.213913-1.9591675-0.4278259-3.7859192-0.6281433-5.7722549c0-1.8539047,0.0237427-3.548233,0.0271454-5.2459564
		C135.087265,79.9290771,135.0906677,79.8985214,135.0770721,79.8985214z"/>
	<path fill="#E0AA79" d="M143.5826721,93.8809509l2.5737305-0.0373535c1.1544647,0.5059204,2.2579803,0.8760223,3.1305847,1.5517197
		c2.4011841,1.7735825,3.3479462,4.9164658,2.3258667,7.7212372c-0.9744873,2.9031067-3.4497681,4.6721268-6.8689728,4.8452988
		c-1.1849976,0.0611115-2.3768005,0-3.7112274-0.0203705c-0.1833649-3.6568985-0.213913-7.2968063-0.1188354-11.1268692
		C142.5877838,96.3562317,143.419693,95.4292755,143.5826721,93.8809509z"/>
	<path fill="#D19B6F" d="M126.2760925,134.2697296c0.0645142-0.1629791,0.2207184-0.2444763,0.4753723-0.3123779
		c0.0729294,0.6987,0.0729294,1.4030762,0,2.1017761C126.5884933,135.5837708,126.4798355,134.9624023,126.2760925,134.2697296z"/>
	<path fill="#6F6672" d="M81.6192551,175.3104401H87.39151c4.4921646,0.0441437,7.7755737,2.1289368,9.4630966,5.9250488
		c1.8844604,4.2476959,3.1102142,8.7941895,4.6415634,13.3508759c-1.6977234,0.1528015-3.4260101,0.1697693-5.3070831,0.1901398
		c-0.4121552-0.4058533-0.7378616-0.8909607-0.9575119-1.4260864c-0.937149-2.7163544-1.9116364-5.4327087-2.7163544-8.1762085
		c-0.7062607-2.4379272-2.2002411-4.0032349-4.6823044-4.223938c-2.2367554-0.1833954-4.4860306-0.1492767-6.7161942,0.1018524
		c-2.2511826,0.2376862-3.4327927,1.9048462-4.1594162,3.9251404c-1.1578369,3.2188721-2.3360672,6.4309692-3.602562,9.7211456
		c-1.7995834,0.0713043-3.5007019,0.0679169-5.3613968,0.0611115c1.2664948-4.3291779,2.4549026-8.7568359,4.1797867-12.9604034
		C73.802948,177.8026886,77.062561,175.5854645,81.6192551,175.3104401z"/>
	<path fill="#6F6772" d="M132.2996216,183.8873291c1.8471069-0.0984802,3.629715-0.0984802,5.5481415-0.115448
		c1.4498596,1.0967407,2.9812164,2.037262,4.0473938,3.3445129c2.9404602,3.6297302,1.8606873,8.9334106-1.9014435,11.7719879
		c-4.9980774,3.7689514-11.6158142,0.2444763-12.7974243-4.1967621c-1.018631-3.8979645-0.3395462-6.8995361,3.0185547-9.4766693
		C130.8362122,184.7361908,131.572998,184.3898621,132.2996216,183.8873291 M136.7476501,194.2909546
		c1.0695648-0.879425,1.650177-2.037262,0.9303436-3.2664185c-0.4549713-0.7673645-1.4736023-1.6739502-2.2375793-1.6739502
		c-0.9337463,0-2.2341919,0.6790924-2.689209,1.4532471c-0.4074402,0.7062531-0.1765747,2.1221619,0.2920074,2.9132843
		C133.8173828,195.0039978,135.2026978,194.9734497,136.7476501,194.2909546z"/>
	<path fill="#D19A6E" d="M134.9243011,79.9188919c0.1598969,1.7487869,0.151947,3.508873-0.0237579,5.2561417
		c-12.9026718,0.156189-25.6220093,0.1493988-38.3447189,0.1629868c-3.5957794,0-6.0506821,2.2409897-6.4513397,5.7043381
		c-1.3072357,0.0475388-2.4616928,0.0713043-3.7587585,0.0780945c-0.8182907-0.0271606-1.4973755-0.0339508-2.1730881-0.040741
		c0.2003403-3.6127548,0.4006577-7.2221069,0.5942001-10.9910507
		C101.4146805,79.922287,118.0896912,79.9154968,134.9243011,79.9188919z"/>
	<path fill="#5E5461" d="M86.4747391,91.1374359c1.1510544-0.0237732,2.3054962-0.0475388,3.7689285-0.0713043
		c3.3954391,0,6.4852829,0.156189,9.5581818-0.0203781c3.0728912-0.1765594,5.4904175,0.7096481,7.0659103,3.6025696
		c0.1222305,2.2613602,0.2444611,4.3767242,0.3667145,6.4954834c-0.3565216,7.1847534-4.7196732,10.556427-12.3797913,9.4664917
		c-1.3581772-0.1969376-2.6824036-0.7537918-4.1492157-1.1612396c-4.7875671-2.872551-6.4513397-7.1304321-5.948822-12.7363052
		c0.1595764-2.2070312-0.6044006-4.3869095,1.7180939-5.5753098 M96.3215179,105.0315857
		c4.8724518,0.0509338,5.2799072-0.3701019,5.1237335-5.269722c-0.0105743-0.1582794-0.0105743-0.3170853,0-0.4753647v-2.587326
		H90.4474106c-0.8522644,5.0388336,1.3785477,8.3256149,5.8673096,8.3256149L96.3215179,105.0315857z"/>
	<path fill="#4B404F" d="M86.3287354,91.1204605c-2.1764679,1.2053833-1.4124985,3.3954391-1.5551224,5.4327011
		c-1.8063736,0.1426086-3.6025543,0.115448-5.5583344,0.1120529c-1.6128311-0.258049-2.6959839-0.9507217-2.9302673-2.6823959
		c0.3802872-2.2375946,1.120491-2.8793335,3.2086945-2.8963089c1.4464645,0,2.8929291,0,4.4989624,0
		C84.831337,91.1034775,85.5104218,91.1034775,86.3287354,91.1204605z"/>
	<path fill="#5E5461" d="M135.0838776,96.7500992c0.3395386,4.091507-0.2784119,7.8638458-3.4429932,10.8654175
		c-3.300354,3.1305923-10.0063553,4.2239304-14.1148453,2.2341995c-3.5074997-1.6977234-4.753624-4.7536163-4.9403687-8.7161026
		c0.1561966-2.410759,0.275032-4.5125351,0.4855499-6.678833c1.1306839-1.9761429,2.6416626-3.3037567,4.8826447-3.330925
		c5.1339035-0.0543289,10.2712173,0,15.4798355,0.115448c1.2596893,0.6790848,1.7689972,1.6264114,1.6365814,2.9811935
		c-0.0475006,0.8447952-0.0475006,1.691597,0,2.5363922 M124.1267929,105.0247879
		c3.2222748-0.217308,4.8996201-1.9014435,5.4327164-5.7179184v-2.587326h-11.2049637c0,1.1374741,0.0475388,2.1459198,0,3.1509705
		C118.099884,104.9263229,119.390152,105.2998199,124.1267929,105.0247879z"/>
	<path fill="#4B404F" d="M135.2366943,96.7161407c-0.1790619-0.8213043-0.2351379-1.66465-0.1663818-2.5024338
		c0.132431-1.3581772-0.3768768-2.2885284-1.4906006-3.0049667c0.6974182-0.0971832,1.4010315-0.1425781,2.1051636-0.1358185
		c1.5686951,0,3.1373749,0.0509338,4.7026825,0.0203705c1.7792053-0.0339508,2.8827209,0.7130432,3.1781158,2.6552353
		c-0.1460114,1.6977234-0.97789,2.6077042-2.6484528,2.916687
		C138.9920349,96.7025681,137.1924744,96.6957703,135.2366943,96.7161407z"/>
	<path fill="#4D4150" d="M112.9795532,94.4989243c-0.1188278,2.1017761-0.2376862,4.2035522-0.3667145,6.4785004
		c-2.037262-2.037262-3.4260101-2.094986-5.2765045,0.0746994c-0.2291107-2.1507263-0.3424454-4.3122101-0.3395462-6.4751053
		C109.0781937,93.6262894,111.0373688,93.6194992,112.9795532,94.4989243z"/>
	<path fill="#E1A875" d="M113.0712357,94.4479904c-1.9457855-0.8235703-4.1422348-0.8235703-6.0880203,0
		c-1.6977234-2.6891861-4.091507-3.5787964-7.181366-3.3954391c-3.0898666,0.1833496-6.1627426,0.0271606-9.4019852,0
		c0.1052628-3.4633484,2.5601578-5.7043457,6.1559372-5.7043457c12.7227097,0,25.4658203,0,38.3311462,0
		c0.3734894,1.8267441,0.5874023,3.6534958,0.8013306,5.6126633c-0.7217102,0.1184387-1.4519348,0.1774902-2.1832886,0.1765671
		c-5.2799072,0-10.4172211-0.057724-15.5511246,0C115.712883,91.1442261,114.2019043,92.4616547,113.0712357,94.4479904z"/>
	<path fill="#D7D5D8" d="M136.6423645,194.3554688c-1.4396667,0.617981-2.8249817,0.6519318-3.5856018-0.6383514
		c-0.4685669-0.7911224-0.6994781-2.2070312-0.2920074-2.9132843c0.4549866-0.7809448,1.7554626-1.4532471,2.689209-1.4532471
		c0.7639771,0,1.782608,0.9065857,2.2375793,1.6739502C138.4114227,192.2469025,137.83078,193.4115295,136.6423645,194.3554688z"/>
	<path fill="#CD986E" d="M96.0023422,104.9976273c-4.1831818,0-6.4139938-3.2596283-5.5617294-8.2984619h11.0080109
		c0.0265503,0.916626-0.0302734,1.8337784-0.1697693,2.7401199c-0.786499,0.1182251-1.5816422,0.169342-2.3768082,0.1527939
		c-1.9795456,0.0169754-2.8895264,0.9065781-2.9065018,2.858963
		C96.0023422,103.2999039,96.0023422,104.1487656,96.0023422,104.9976273z"/>
	<path fill="#E1A875" d="M96.1619186,105.0247879c-0.133728-0.8475952-0.1871567-1.7059174-0.1595764-2.5635605
		c0-1.9523773,0.9269485-2.84198,2.9065018-2.8589554c0.7334061,0,1.4702301,0,2.3768082,0
		c0.1595764,0.0577164,0.1697693,0.1154404,0.1697693,0.1697693
		C101.5946274,104.6614761,101.1871948,105.0825119,96.1619186,105.0247879z"/>
	<path fill="#CE986E" d="M123.8449707,104.9840469c-4.4650192,0.315773-5.7552795-0.057724-5.4768524-5.1169357
		c0.0543213-1.018631,0-2.0134888,0-3.1509705h11.2049561c0.0233612,0.914444-0.0345917,1.829155-0.173172,2.7333374
		c-0.7870178,0.1126328-1.5819702,0.1603317-2.3768082,0.1426086c-2.037262,0.0169754-3.0287247,0.8963928-3.1815262,2.8725433
		C123.7974243,103.2999039,123.8449707,104.1419754,123.8449707,104.9840469z"/>
	<path fill="#E1A875" d="M123.9909668,105.0010223c-0.1431351-0.8373795-0.1875916-1.6886597-0.1324234-2.5363998
		c0.1528015-1.9761505,1.1476593-2.8555679,3.1815262-2.8725433c0.7266312,0,1.4566345,0,2.3326721,0
		C129.026413,103.1199493,127.3490677,104.8040924,123.9909668,105.0010223z"/>
	<path fill="#F9595F" d="M235.7934265,120.3619232c2.0102081,1.6230698,3.502243,3.5867615,4.5755005,5.8776855
		c0.8405304,1.7941589,1.3404388,3.6777344,1.4992981,5.6503754c0.063385,0.7871246,0.1397095,1.5773773,0.1311035,2.3653564
		c-0.0257416,2.3555603-0.6531372,4.5774841-1.5492096,6.7401733c-1.2713318,3.0684052-3.1328735,5.7806091-5.2937622,8.2485809
		c-1.9290771,2.2032166-4.0581512,4.2435608-6.2103424,6.2354736c-1.8256836,1.6897278-3.7796173,3.2466888-5.7350311,4.7885895
		c-1.4344177,1.1310883-2.9483337,2.1641235-4.4546661,3.2003937c-1.0986481,0.7558289-2.1834869,1.5698547-3.3786316,2.139389
		c-2.557373,1.2187042-5.125824,1.1443329-7.5315094-0.454834c-2.3197174-1.5419922-4.6346283-3.0960999-6.8829041-4.7389832
		c-1.5926208-1.1637726-3.1025696-2.4476776-4.591568-3.7443848c-1.6716309-1.4557343-3.3621521-2.9055023-4.8951721-4.501297
		c-3.0029602-3.125946-5.7641449-6.4560547-7.5975647-10.4389038c-0.8873596-1.9276733-1.5395508-3.9214783-1.7590942-6.0644531
		c-0.4836731-4.7209473,0.6393433-9.0060577,3.5449829-12.7245712c2.3590851-3.0190659,5.4872742-4.9359207,9.3109283-5.6362762
		c4.3987274-0.805687,8.4715576-0.0213699,12.1865082,2.4748383c1.7256012,1.1594925,3.2931519,2.5054932,4.4950256,4.2319183
		c0.0852814,0.1224823,0.2225494,0.2087708,0.3118591,0.2902069c0.848053-0.9068146,1.613327-1.8311691,2.4865265-2.6388321
		c2.5805969-2.3869171,5.5577698-4.0114288,9.0894928-4.4866943c3.6465454-0.490715,7.1095581,0.0415802,10.3168335,1.9109116
		C234.5131836,119.4661102,235.1228333,119.9168549,235.7934265,120.3619232z"/>
</g>
</svg>
            </SvgIcon>


)
}