import * as React from 'react';
import {SvgIcon} from "@mui/material";


export default function Map(props) {
    return (


        <SvgIcon {...props}>
            <svg version="1.1" id="Слой_1" x="0px" y="0px"
                 viewBox="0 0 278 278" enableBackground="new 0 0 278 278">
                <g>
                    <g id="XMLID_1_">
                        <circle fill="#FFFFFF" cx="139" cy="139" r="138.5"/>
                        <path fill="#AA8C8C" d="M139,1c36.8611145,0,71.5159607,14.3545074,97.580719,40.4192657
			C262.6455078,67.4840393,277,102.1388855,277,139s-14.3544922,71.5159607-40.419281,97.580719
			C210.5159607,262.6455078,175.8611145,277,139,277s-71.5159607-14.3544922-97.5807343-40.419281
			C15.3545074,210.5159607,1,175.8611145,1,139s14.3545074-71.5159607,40.4192657-97.5807343S102.1388855,1,139,1 M139,0
			C62.2324219,0,0,62.2324219,0,139s62.2324219,139,139,139s139-62.2324219,139-139S215.7675781,0,139,0L139,0z"/>
                    </g>

                    {/*<linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="738"*/}
                    {/*                y1="221.1186371" x2="937" y2="221.1186371"*/}
                    {/*                gradientTransform="matrix(1 0 0 1 -704.5 -87.0499878)">*/}
                    {/*    <stop offset="0" style="stop-color:#FFFFFF"/>*/}
                    {/*    <stop offset="1" style="stop-color:#000000"/>*/}
                    {/*</linearGradient>*/}
                    <path fill="#3A3737" stroke="url(#SVGID_1_)" strokeMiterlimit="10" d="M34,218.1909332
		c0-44.2233429,0.0386581-88.4853516,0.1546288-132.8246765c0.2319374-0.5798492,0.3092537-0.9664154,0.4638786-1.3143311
		c1.9714966-4.4068756,5.759861-5.7985153,10.2053909-5.7985153c28.7219849-0.0386581,57.4439697-0.0386581,86.1659393-0.0386581
		c0.9277649,0,1.8555298-0.1932831,2.7446442-0.5411911c5.3346252-2.1647797,10.7852325-3.7883682,16.5837555-2.8606033
		c3.4790955,0.5411911,6.9195557,1.5076141,10.3600006,2.3194046c1.2756653,0.3092575,2.4740295,1.0437317,3.7497101,1.0823898
		c8.1179199,0.0773163,16.2358551,0.0386581,24.353775,0.0386581c0.502533,0,0.9664154-0.0773163,1.4689484-0.1159744
		c0-3.4404526-0.0386505-6.7262802,0-10.0121002c0.0386658-2.6673203-0.0773163-5.4119492,0.4252319-8.0019531
		c2.2034302-10.8238983,11.3650818-18.1686821,22.4982452-17.0476379
		c6.5716553,0.6571655,11.9062805,3.4791107,15.3467407,9.1616554c1.4689484,2.435379,2.3194122,5.2186661,3.4791107,7.8473282
		c0,19.7149544,0,39.4299126-0.1159668,59.3768044c-0.8504486,0.6571655-1.7395477,1.2370224-2.7059784,1.7008972
		c-2.4740295-0.6571655-2.9765778-1.3143234-2.9765778-3.904335c0-17.7821121,0.0386658-35.5642319-0.0386505-53.3463478
		c-0.0386658-6.1464272-2.7446289-10.8625526-8.5431519-13.6844978
		c-10.9785156-4.4068718-21.2225647,2.5513458-21.2225647,14.303009c0,41.0921478,0,82.1843109,0,123.2764435
		c0,0.5411987,0.0773163,1.0823975,0.1159668,1.8168793c4.2909088-4.2135925,9.2776184-6.0691223,15.1147919-6.2237396
		c5.6439056,0.2705994,10.3600159,2.319397,14.5349579,6.3397064v-2.3967285c0-18.2460022,0-36.4533386,0-54.6993256
		c0-2.5513611,0.6571655-3.3244934,3.2471619-3.7883759c0.9277649,0.5411987,1.739563,1.0823975,2.5513458,1.5849304
		c0,25.6681061,0,51.2975464-0.1546478,77.1975861c-0.6184998,4.3682098-2.5899963,7.8473206-5.3346252,10.7852325
		c-4.2909088,4.6388092-9.8574829,6.6103058-16.0425568,6.6876221c-10.050766,0.1159515-20.101532,0.0386505-30.1522827,0.0386505
		h-39.4299011c-2.7832947,0-3.5950775-0.618515-4.05896-3.2471619c0.7344818-2.3967285,1.3916473-2.8992615,3.9816437-2.8992615
		c22.9234619,0,45.8855743,0,68.8090515,0c1.4689636,0.0386658,2.9765778-0.0386658,4.4068756-0.2705994
		c8.0792542-1.5076141,13.3752441-9.3162842,11.86763-17.3955536c-1.0823822-5.7211914-5.4119415-10.2826996-11.0558319-11.6743469
		c-6.8422546-1.7008972-13.684494,1.5076141-16.9703217,7.9246368c-1.1983643,2.3194122-2.6286621,3.1698608-4.5228577,2.7446289
		c-1.8941803-0.4252167-2.7832947-1.8555145-2.7832947-4.4455261c0-9.5482178-0.0386505-19.057785,0.0386658-28.6060028
		c0-1.1983643-0.3479004-1.8555298-1.4302979-2.5513611c-4.7934418-3.1311951-7.8859863-7.4607544-8.6591187-13.2979279
		c-0.2319489-2.0874634-0.618515-4.1749268-1.121048-6.2623901c-0.1546173-0.618515-1.1596985-1.3916473-1.8168793-1.4689636
		c-1.7782135-0.2319336-3.5950623-0.2319336-5.4119415-0.0773163c-0.7731323,0.0773163-1.9328461,0.5798492-2.2034454,1.1596985
		c-1.2756653,2.8219452-2.2807465,5.7598572-3.4404449,8.7364349c0.1159668,0.1546326,0.2705994,0.2706146,0.4252319,0.3479156
		c2.6286469,0.9664154,5.2186584,2.1261139,7.9246368,2.7832947c3.7497101,0.8890991,5.5665741,3.0538788,4.6001434,6.8035889
		c-0.502533,1.8941803-1.6235809,3.7497101-2.8219299,5.3346252c-1.0437469,1.4303131-2.5900116,2.4740448-3.9429932,3.6723938
		c-1.6235962,1.4689636-2.6286621,3.2085114-2.7832947,5.4892731c-0.2319489,4.7161255-1.3529968,9.3162842-3.2858276,13.607193
		c-1.7008972,3.633728-3.8656769,6.8422546-7.7313538,8.5044861c-5.6825562,2.435379-10.5919495,0.0386658-11.9449463-5.991806
		c-0.5798492-2.5126801-0.9664154-5.0640411-1.1983643-7.615387c-0.5798492-7.0355377-3.7497101-12.3314972-10.0121002-15.6946411
		c-1.4302979-0.7731476-2.8219299-1.5849304-4.2135773-2.358078c-3.2085266-1.7782135-4.8707657-4.4841766-4.7934418-8.1952362
		c0.0386581-2.0101624,0-3.9816437,0.1159668-5.991806c0.4638824-6.9195557,3.2085114-9.8961334,10.0121155-11.1331329
		c0.2319336-0.0386658,0.4638824-0.1159668,0.7731323-0.1932831c-1.8555145-3.4017944-3.2858276-6.8422546-7.2288208-8.5045013
		c-1.9328308-0.8117981-3.5564194-2.5126801-5.1413498-3.9816437s-2.937912-3.2085114-4.3682175-4.8320999
		c-1.0050735,0.8504486-1.9328384,1.7782135-3.0152283,2.4353867c-2.8992538,1.816864-4.6388092,4.3682098-5.4506073,7.6540222
		c-0.1932831,0.7344818-0.4638824,1.4689636-0.8117905,2.1647949c-1.0823975,2.4353638-2.9379196,3.8656769-5.6052322,4.0976105
		c-1.1597061,0.1159668-2.3194122,0.0773163-3.4791107,0.0386658c-2.0101547,0-3.9816513-0.2319489-5.9531403-0.0386658
		c-2.4740372,0.2319489-3.8270187,1.9714966-4.0976257,4.6388092c-0.2319412,2.3194122,0.5025406,3.6723938,3.1698532,4.2909088
		c2.3580704,0.5411987,4.7934418,0.695816,7.1901627,0.4252319c3.4791107-0.3865662,5.7212067,0.3479004,7.4221039,3.4791107
		c1.0050735,1.8555145,1.7395554,3.8656769,2.5513458,5.7985077c0.9277649,2.1647797,2.0874634,4.0976257,4.2909088,5.2573242
		c3.6337357,1.9714966,5.3732834,5.1800079,5.3346329,9.2003174c-0.0773087,7.615387-3.9429855,13.065979-10.4759903,16.5450897
		c-2.5899963,1.4302979-4.3682098,3.3244781-5.3346329,6.0304565c-0.8117981,2.2420959-1.3529892,4.6001587-2.3580627,6.7262726
		c-0.966423,2.0488129-2.0488129,4.252243-3.6723938,5.7598572c-4.5615005,4.1362915-10.8238983,1.8555298-11.7516556-4.1749268
		c-0.3092575-2.1261139-0.1546249-4.3682098-0.0773163-6.5329895c0.1546249-4.4455261,0.4638824-8.8524017,0.5025406-13.2979279
		c0.0386581-2.1647797-1.0050812-3.8270111-2.9379196-5.1026917c-7.5767288-4.9867249-9.2003098-11.365097-4.9867249-19.483017
		c1.5849304-3.0538788,1.1597061-4.252243-1.9328384-5.4892731c-6.6489639-2.7059631-8.5431442-5.5665588-8.6591187-12.7567291
		c-0.0386505-2.0874634-0.0773087-4.4455261-1.0437317-6.1850891c-2.3967133-4.6001434-2.590004-9.0456696-0.6571579-13.7618103
		c0.9664154-2.3580627,0.2705917-4.252243-1.5849304-5.8371735c-1.468956-1.2756729-3.0152283-2.4740295-4.2909012-3.904335
		c-5.2573204-5.8758316-2.9765701-14.5736008,4.4841843-17.3182373c6.9968719-2.5513458,13.8777847-2.4740295,20.6427155,0.6958237
		c3.0152359,1.4302979,5.991806,2.9765701,8.929718,4.5615005c3.2471695,1.7782135,7.1128464,2.1261215,10.6306152,0.9277573
		c1.0823898-0.347908,2.1647797-0.8891068,3.3244858-1.0437317c3.7110519-0.4252243,6.0304565-2.2034302,6.4170227-6.0691071
		c0.0386581-0.1932831,0.1159744-0.3865738,0.2319489-0.5411911c2.0488052-3.5177689,4.0976181-7.0355377,6.1077652-10.4759903
		h-1.7782135c-18.7485352,0-37.4970703,0-56.2456055,0c-3.4017944,0-6.842247,0.0386581-10.4373283,0.0386581
		c-3.7110481-0.0386581-7.2674713-0.0386581-10.7852402-0.0386581c-4.05896,0-4.8707504,0.8117905-4.8707504,4.8320999
		c0,41.6720047,0,83.3053513,0,124.9773483c0,0.502533-0.0386581,1.0437317,0,1.5462646
		c0.1159706,2.0101624,1.2756729,3.2471771,3.324482,3.2471771c4.05896,0.0386505,8.1179199-0.0386658,12.3315086-0.0386658
		c23.3100395,0.0386658,46.4654427,0.0386658,69.5821991,0.0386658c2.7446289,0,3.4791107,0.5411835,4.05896,3.1698608
		c-0.6571655,2.4353638-1.3529892,2.937912-3.9816513,2.937912c-27.1370544,0-54.2741089,0-81.4498138-0.0386658
		c-1.5076141-0.0773163-2.9765739-0.3865662-4.3295593-0.9277496C36.5126915,223.1389923,35.0823898,220.7809448,34,218.1909332
		 M152.7536163,140.606781c1.0437317-1.8555145,1.9328308-3.8270111,3.2085114-5.4892578
		c1.1596985-1.5462799,2.5513458-3.1312103,4.1749268-4.05896c10.4759827-5.8758316,15.6946411-15.9265976,14.187027-27.8715439
		c-1.6235809-12.9500198-14.0323944-23.3100357-27.4076385-22.6528702
		c-11.9449463,0.3092575-22.2276459,8.6591187-25.0495987,20.4107742
		c-2.8219452,11.5970383,2.5513535,23.8898926,13.0660019,29.5724411c2.3580627,1.198349,4.252243,3.1311951,5.4892578,5.450592
		c2.3580627,4.4068756,4.9094238,8.6977844,7.4994202,13.3365936
		C149.5450897,146.3279877,151.0527039,143.5833588,152.7536163,140.606781 M146.5685272,155.9921875
		c-1.0050812-0.8504486-2.1261139-1.5849304-2.9765778-2.5900116c-0.7344666-0.9664154-1.3529816-2.0487976-1.816864-3.1698608
		c-0.4638977-1.0050812-1.1597137-1.3529816-2.2420959-1.3529816c-5.1800079-0.0773163-6.8422394,1.4689484-6.9582214,6.6876221
		c0,0.2705994-0.0386505,0.502533,0,0.7731323c0.0773163,6.8422546-0.9277496,5.6052246,5.0640411,8.8910522
		c7.112854,3.9429932,12.2542114,9.35495,13.4525604,17.743454c0.4638824,3.1698608,0.8890991,6.3783722,1.5076141,9.5095673
		c0.5798492,2.8992615,2.0487976,3.4791107,4.5614929,1.9328461c0.8117828-0.502533,1.7008972-1.121048,2.1261139-1.9328461
		c1.3916473-2.7832794,2.9379272-5.5665741,3.8270264-8.5431366c0.8890991-3.053894,1.0437317-6.3397217,1.6235809-9.7801666
		c0.8504639-1.5076141,1.5462799-3.1311951,2.6673126-4.4455261c1.4303131-1.6622314,3.2085114-2.9765778,4.7547913-4.5228424
		c0.695816-0.7344818,1.2370148-1.6235962,1.8941803-2.5513611c-2.1261292-0.5798492-3.9043427-0.9277496-5.5665741-1.6235809
		c-1.739563-0.695816-3.4017944-1.5462799-4.9480743-2.5513458c-3.5950623-2.3967285-4.05896-5.2186737-1.9328308-8.929718
		c1.0050812-1.7782135,1.816864-3.6337433,2.4353638-5.6052399c1.0437469-3.2471619,2.9379272-5.4892578,6.3783722-5.9144745
		c2.3580627-0.2319489,4.754776-0.3092499,7.1128387-0.2319489c4.0589752,0.0386658,6.417038,1.7395477,7.4994202,5.6438904
		c0.695816,2.5126953,0.9664154,5.1800079,1.3529816,7.7700195c0.4638977,2.8219299,1.9328461,4.9867249,4.0976257,7.0355377
		v-54.4673843c-3.0152283-0.2705994-6.0691223,0.4252243-8.6591187,2.0101547
		c-0.695816,0.4638748-1.121048,1.2370148-1.2370148,2.0488052c-0.695816,12.5247955-6.3783722,21.8797379-17.2795868,28.1034775
		c-1.0437317,0.618515-1.8941803,1.4689484-2.4740295,2.5126953c-2.8605957,4.8707581-5.6438904,9.8188171-8.3885193,14.7668915
		c-1.23703,2.2420807-3.0152283,3.2471619-5.7598572,2.7446289 M101.301445,157.4998016
		c-1.9714966-0.1546326-4.0203018-0.0386658-5.9144897-0.502533c-3.7497025-0.8890991-7.2288132-2.6286621-8.2725449-6.6489716
		c-1.1597061-4.4068756-0.2319412-8.5431519,3.5564194-11.9062805c1.9715042-0.7344818,3.8656769-1.7782135,5.8758316-2.1647797
		c2.0101471-0.3865662,4.2135849-0.0773163,6.3397064,0.0386505c3.2471695,0.1546326,3.2858276,0.2319489,4.2135849-2.8219452
		c1.1983643-4.0202942,3.3631439-7.3061218,6.8809128-9.6641922c1.1983566-0.8891068,2.2807465-1.8941803,3.2085114-3.0152283
		c0.347908-0.4252243,0.5411911-0.9664154,0.4638824-1.5076065c-0.9277649-4.2135925-1.9328384-8.4271851-2.9379196-12.8727112
		l-3.2085114,1.0050812c-3.6337357,1.1596985-7.3447876,2.3194046-11.1331558,1.2756729
		c-3.1312027-0.8891068-6.0691147-2.3580627-9.0456848-3.6337357c-2.9765701-1.2756653-5.9531403-2.6286621-9.2389679-4.05896
		c-4.05896-1.1983643-8.0406036-0.7344742-11.9449387,0.6185074c-4.5228424,1.5849304-5.3732986,5.7985153-1.8555298,9.0456848
		c1.3529892,1.2756729,2.8606033,2.435379,4.1362762,3.7883682c2.6673126,2.8219452,3.6337357,6.1464233,2.3580627,9.9734497
		c-0.3092575,0.9277573-0.5411987,1.8555222-0.8504486,2.7446289c-0.77314,1.9328461-0.6185074,4.1362762,0.3865662,5.9531403
		c1.7782135,3.2085114,2.3194046,6.6489716,2.2807465,10.2440491c-0.0386505,4.677475,0.6571655,5.6438904,4.9480743,7.3447876
		c0.3092499,0.1159668,0.6185074,0.2319336,0.8891068,0.3479004c4.9480667,2.0488129,6.9582138,7.3061371,4.6001511,12.1382294
		c-0.5798492,1.1596985-1.1597061,2.2807465-1.6235809,3.4791107c-1.1983643,3.1311951-0.4638824,5.7985229,2.0488052,8.0019531
		c0.77314,0.6571655,1.6235886,1.2756653,2.4740372,1.8168793c2.6286621,1.6235809,4.4455261,3.9816437,4.7161255,6.9582214
		c0.347908,3.9429932,0.2705917,7.9632874,0.2319412,11.9062805c-0.0386581,2.937912-0.3865662,5.9144745-0.4638824,8.8524017
		c0,0.6571655,0.5411911,1.8555145,0.8117981,1.8555145c0.8117905-0.0386658,1.5849304-0.3479156,2.1647797-0.8890991
		c0.5411911-0.618515,0.9664154-1.3529968,1.2370148-2.1647797c0.8117981-2.1647797,1.5076141-4.3682098,2.2807465-6.5716553
		c1.5076141-4.2909088,3.9429932-7.7700195,8.4271774-9.8961334c3.9816513-2.0101624,6.3397064-5.29599,7.2674789-9.6255341
		c0.6185074-2.860611-0.3092575-4.7934418-2.783287-6.3010559c-1.121048-0.695816-2.3967209-1.4689484-3.0925446-2.5126953
		c-1.5849304-2.4740295-2.9379196-5.0640259-4.1749344-7.7313538
		C105.0898056,157.0745697,105.2057724,157.0359192,101.301445,157.4998016 M190.5212708,93.4455261v-8.8910599h-18.9418182
		c1.6622467,2.4740295,3.5177765,4.7934418,4.9094238,7.3447876c1.3916473,2.5513535,2.2807465,5.373291,3.4017792,8.0792618
		c1.6622467-0.502533,3.3244934-1.1983566,5.0640411-1.5462646c1.7782135-0.3865738,3.6337433-0.4252319,5.5665741-0.6571655
		V93.4455261z"/>
                    <path fill="#FFFFFF" d="M231.9226837,130.2467804c-0.9277649-0.4252319-1.7782135-0.9277649-2.5513458-1.5849304
		c-0.1546326-2.6286697-0.1546326-5.0253906-0.1546326-7.460762c0.8117981-0.5025406,1.6235962-1.0050812,2.5513458-1.5849304
		C231.9226837,123.0566101,231.9226837,126.5357208,231.9226837,130.2467804z"/>
                    <path fill="#23A7FD" d="M136.7883606,221.9019928c-2.435379,0.1546173-4.8707581,0.1546173-7.4607544,0.1546173
		c-0.8117981-2.4740295-1.5076141-3.0152283-4.2909088-3.0152283c-23.1554108,0-46.2721558,0-69.6208496-0.2319336
		c-0.3092537-0.9664154-0.5025368-2.0101624-0.5798531-3.0538788c0-42.715744,0-85.4701385,0-128.1858826
		c0-1.0437317,0.5025406-2.0874634,0.7344818-3.1311951c3.4017982-0.0386581,6.8422508-0.0773163,10.2440414-0.0773163
		c18.7485428,0,37.4970779,0,56.2456131,0h1.7395554c-2.0101547,3.4791107-4.05896,6.9968796-6.1077728,10.4759903
		c-0.1159744,0.1546249-0.1932831,0.347908-0.2319412,0.5411911c-0.3865738,3.8656769-2.7059784,5.6438904-6.4170303,6.0691147
		c-1.1210403,0.1159668-2.2034302,0.6958237-3.3244858,1.0437317c-3.5177689,1.1983566-7.3834457,0.8891068-10.6306076-0.9277649
		c-2.9379196-1.5462646-5.9144897-3.1311951-8.929718-4.5615005c-6.7649384-3.1698532-13.6458435-3.28582-20.6427231-0.6958237
		c-7.4607506,2.7446365-9.7415047,11.4037552-4.4841805,17.3182373c1.2756767,1.4302979,2.8219414,2.6286621,4.2908974,3.904335
		c1.8555298,1.6235886,2.5513458,3.4791107,1.5849304,5.8371811c-1.9328384,4.677475-1.7395554,9.1616516,0.6571655,13.7618103
		c0.9277573,1.7782135,0.9664154,4.0976257,1.0437317,6.1850891c0.1546249,7.190155,2.0101471,10.0507507,8.6591187,12.7567291
		c3.0925369,1.23703,3.5177612,2.435379,1.9328384,5.4892731c-4.1749344,8.0792542-2.590004,14.4962921,4.9867249,19.4830017
		c1.9328384,1.2756805,2.9765701,2.9379272,2.937912,5.1027069c-0.0773087,4.4455261-0.3865662,8.8524017-0.5025406,13.2979279
		c-0.0773087,2.1647797-0.2319412,4.4068604,0.0773163,6.5329895c0.9277573,6.0304565,7.1901627,8.311203,11.7516556,4.1749268
		c1.6235809-1.4689484,2.7059784-3.7110596,3.6723938-5.7598572c1.0050812-2.1261292,1.5462723-4.4841919,2.3580627-6.7262726
		c0.966423-2.7059784,2.7446365-4.6001587,5.373291-5.991806c6.5329971-3.5177612,10.398674-8.929718,10.4759903-16.5450897
		c0.0386581-4.0203094-1.6622467-7.2288208-5.3346405-9.2003021c-2.2034302-1.1597137-3.3631363-3.0925446-4.2909012-5.2573242
		c-0.8117905-1.9328461-1.5462723-3.9430084-2.5513458-5.7985229c-1.7008972-3.1311951-3.9429932-3.8656769-7.4221039-3.4791107
		c-2.3967209,0.2705994-4.8320923,0.1159668-7.1901627-0.4252319c-2.7059708-0.6184998-3.4017944-1.9714966-3.1698532-4.2908936
		c0.2319412-2.6673126,1.6235809-4.3682098,4.0976181-4.6388245c1.9715042-0.1932831,3.9816513,0.0386658,5.9531403,0.0386658
		c1.1597061,0,2.3194122,0.0386658,3.4791107-0.0386658c2.6673203-0.2319336,4.5228424-1.6622314,5.6052322-4.0976105
		c0.347908-0.695816,0.618515-1.4303131,0.8117981-2.1647797c0.8117905-3.2858276,2.5513458-5.8371735,5.4506073-7.6540527
		c1.0823898-0.6571579,1.9714966-1.6235809,3.0152283-2.4353714c1.468956,1.6235809,2.783287,3.3631363,4.3682175,4.8320999
		c1.5849304,1.4689484,3.208519,3.1698456,5.1413498,3.9816437c3.9429932,1.6622314,5.373291,5.1026917,7.2288208,8.5044861
		c-0.3092499,0.0773163-0.5411987,0.1546173-0.7731323,0.1932831c-6.8036041,1.1983643-9.5482254,4.2135925-10.0121078,11.1331482
		c-0.1159744,1.9714966-0.0773163,3.9816437-0.1159744,5.9917908c-0.0773087,3.7110596,1.5849304,6.417038,4.7934418,8.1952515
		c1.3916321,0.7731323,2.7832794,1.5849304,4.2135925,2.3580627c6.2624054,3.3631439,9.432251,8.6591187,10.0121002,15.6946564
		c0.2319489,2.5513458,0.618515,5.1026917,1.1983643,7.615387c1.3529816,5.9917908,6.3010406,8.4271698,11.944931,5.9917908
		c3.8656769-1.6622314,6.0304718-4.8707428,7.731369-8.5044861c1.9328308-4.2909088,3.0538788-8.929718,3.2858124-13.607193
		c0.1546326-2.319397,1.121048-4.0202942,2.7832947-5.4892578c1.3529968-1.1983643,2.8992615-2.2807465,3.9429932-3.6723938
		c1.1983643-1.5849304,2.3194122-3.4404449,2.8219452-5.3346252c0.9664154-3.7497253-0.8504486-5.9144897-4.6001587-6.8036041
		c-2.7059631-0.6571655-5.2959747-1.816864-7.9246368-2.7832947c-0.1546173-0.0773163-0.3092499-0.1932831-0.4252167-0.3479004
		c1.1596985-2.9765778,2.1647797-5.9144897,3.4404449-8.7364349c0.2705994-0.618515,1.4302979-1.121048,2.2034454-1.1596985
		c1.816864-0.1546326,3.633728-0.1546326,5.4119415,0.0773163c0.695816,0.0773163,1.6622314,0.8504486,1.8168793,1.4689484
		c0.502533,2.0487976,0.8890991,4.1362762,1.1210327,6.2624054c0.8117981,5.7985077,3.9043427,10.1280823,8.6591187,13.2979279
		c1.0823822,0.695816,1.4303131,1.3529816,1.4303131,2.5513458c-0.0386658,9.548233-0.0386658,19.057785-0.0386658,28.6060181
		c0,2.5899963,0.8890991,4.0202942,2.7832947,4.4455261s3.3244781-0.4252319,4.5228424-2.7446289
		c3.2858276-6.417038,10.1280823-9.6255341,16.9703217-7.9246521c7.9633026,1.9715118,12.8340607,10.050766,10.8625641,18.0140686
		c-1.3916473,5.6438904-5.9531403,9.9734344-11.6743469,11.0558319c-1.4689636,0.2319489-2.9379272,0.3092499-4.4068756,0.2706146
		c-22.9234619,0-45.8855896,0-68.8090515,0C138.2186584,219.0027313,137.5228424,219.5052643,136.7883606,221.9019928z"/>
                    <path fill="#B1DBFC" d="M211.4345856,183.3611755c-5.6825409,0.1932831-10.6692657,2.0488129-14.9601746,6.2624054
		c-0.0386505-0.7731323-0.1159668-1.3143311-0.1159668-1.8168793c0-41.0921478,0-82.1842957,0-123.2764435
		c0-11.7516594,10.2827148-18.7098808,21.1066132-14.1870346c-4.0589752,3.6337357-6.0691223,7.9246368-6.0691223,13.336586
		C211.4345856,103.5736008,211.4732513,143.467392,211.4345856,183.3611755z"/>
                    <path fill="#0193FA" d="M55.4545097,84.3998413c-0.0773125,1.0823898-0.5798531,2.0874634-0.5798531,3.1311951
		c-0.0386543,42.715744-0.0386543,85.4314728,0,128.1858673c0.0773163,1.0050812,0.1932869,2.0101624,0.38657,3.0152283
		c-4.0589638,0.1932831-8.0792656,0.2705994-12.1382294,0.2319336c-2.0488091,0-3.1698532-1.2370148-3.324482-3.2471619
		c-0.0386543-0.502533,0-1.0437317,0-1.5462646c0-41.6333466,0-83.3053589,0-124.9773483
		c0-4.0203094,0.8117943-4.8320999,4.8707542-4.8320999C48.1870346,84.3998413,51.7434616,84.3998413,55.4545097,84.3998413z"/>
                    <path fill="#DFEBFA" d="M211.5892181,183.3998413c-0.1546326-39.9324493-0.1546326-79.8262405-0.1932831-119.7200317
		c0-5.4119492,1.9714966-9.7028503,6.1464233-13.2206192c5.7985077,2.6673203,8.5431519,7.3447876,8.5431519,13.5298729
		c0.0773163,17.7821198,0.0386505,35.5642319,0.0386505,53.3463516c0,2.5513458,0.502533,3.2085114,2.9765778,3.904335
		c0.1546326,2.435379,0.1546326,4.8707504,0.1546326,7.4607544c-2.435379,0.6571655-3.0925446,1.4689636-3.0925446,3.9816437
		c0,18.2460022,0,36.4533539,0,54.6993408v2.3967133C221.949234,185.7192383,217.2331085,183.6704407,211.5892181,183.3998413z"/>
                    <path fill="#00B865" d="M90.4002304,138.5579834c-3.5177689,3.2471619-4.4455261,7.3447876-3.2858276,11.7903137
		c1.0823898,4.05896,4.5228424,5.7598572,8.2725525,6.6489716c1.8941727,0.4638824,3.9429855,0.3479004,6.1077652,0.695816
		c0.4638824,2.3194122-0.2705917,4.1749268-1.2370148,6.0691223c-3.0152283,5.875824-1.816864,10.9398499,3.7497101,14.4962769
		c3.1698532,2.0488129,4.6388092,4.8321075,5.0253754,8.3885345c-4.2135849,2.0101471-6.6876221,5.5279083-8.1952286,9.8188171
		c-0.77314,2.1647797-1.4689636,4.4068756-2.2807541,6.5716553c-0.2319412,0.8117828-0.6571579,1.5462646-1.2370148,2.1647797
		c-0.5798492,0.5411835-1.3529816,0.8504486-2.1647797,0.8890991c-0.2705917,0-0.8504486-1.1983643-0.8117905-1.8555145
		c0.0773087-2.9379272,0.4252243-5.9144897,0.4638748-8.8524017c0.0386581-3.9816437,0.1546249-7.9633026-0.2319412-11.9062805
		c-0.2705994-3.0152283-2.0874634-5.3346405-4.7161255-6.9582214c-0.8891068-0.5411987-1.7008972-1.1597137-2.4740295-1.8168793
		c-2.5126877-2.1647797-3.2471695-4.8707581-2.0488129-8.0019531c0.4638824-1.1983643,1.0823898-2.3194122,1.6235886-3.4791107
		c2.3194046-4.8320923,0.347908-10.0894165-4.6001587-12.1382294c-0.3092575-0.1159668-0.6185074-0.2319336-0.8891068-0.3479004
		c-4.2909012-1.6622467-5.025383-2.6673126-4.9480667-7.3447876c0.0386505-3.5950775-0.5025406-7.0355377-2.2807541-10.2440491
		c-1.0050735-1.816864-1.1597061-4.0202942-0.3865662-5.9531403c0.3092575-0.9277573,0.5411911-1.8555222,0.8504486-2.7446289
		c1.2756729-3.8270187,0.3092499-7.1128464-2.3580627-9.9734497c-1.2756729-1.3529892-2.7446289-2.5126953-4.1362762-3.7883682
		c-3.5177612-3.2471695-2.7059708-7.4607544,1.8555298-9.0456848c3.9043274-1.3529816,7.8859787-1.816864,12.0222549-0.4638748
		c-2.8992615,3.5564194-3.2471695,7.0355301-0.77314,9.8961334c0.9277649,1.1597061,1.9714966,2.2034378,3.1698608,3.1312027
		c4.3682098,3.2471695,5.4892578,6.4556808,3.5177689,11.5583725
		C86.1479874,130.4400635,88.0808258,134.537674,90.4002304,138.5579834z"/>
                    <path fill="#42C98D" d="M146.7618103,156.1081543c2.5513458,0.3865662,4.3295593-0.618515,5.5665741-2.8605957
		c2.7446289-4.9480743,5.5279236-9.8961334,8.3885345-14.7668915c0.6184998-1.0437469,1.4689484-1.8941803,2.4740295-2.5126953
		c10.9012146-6.2237396,16.5837402-15.5786819,17.2795715-28.1034698c0.1159668-0.8117981,0.5411987-1.5849304,1.23703-2.0488129
		c2.5900116-1.5849304,5.6438904-2.2807465,8.6591187-2.0101547v54.5060501
		c-2.1647949-2.0488129-3.6337433-4.252243-4.0976257-7.0355377c-0.4252319-2.5900116-0.6571655-5.2573242-1.3529816-7.7700195
		c-1.0823975-3.9043274-3.4404602-5.6052246-7.4994202-5.6438904c-2.3580627-0.0773163-4.754776,0-7.1128387,0.2319489
		c-3.4404602,0.4252167-5.3346405,2.6673126-6.3783722,5.9144745c-0.618515,1.9328461-1.4302979,3.8270264-2.435379,5.6052399
		c-2.1261139,3.6723938-1.6622314,6.5329895,1.9328461,8.929718c1.5462646,1.0050812,3.2085114,1.8941803,4.9480591,2.5513458
		c1.6622467,0.6571655,3.4404602,1.0050812,5.5665741,1.6235809c-0.695816,0.9277649-1.1983643,1.8555298-1.8941803,2.5513611
		c-1.5462646,1.5462646-3.3244781,2.8992462-4.754776,4.5228424c-1.0823822,1.3143311-1.7782135,2.937912-2.8219452,4.4841919
		c-1.7395477-4.6388092-4.9094086-8.0406036-9.007019-10.6306152c-2.0874634-1.3529816-4.2909088-2.5126953-6.4556885-3.7883606
		C147.6509094,159.0460663,146.8004608,157.8090515,146.7618103,156.1081543z"/>
                    <path fill="#42CA8D" d="M90.5162048,138.5193176c-2.3967209-3.9429932-4.3682175-8.0792542-2.5126953-12.8340454
		c2.0101547-5.1026917,0.8504486-8.311203-3.5177689-11.5583725c-1.1596985-0.9277573-2.2034302-1.9714966-3.1698532-3.1312027
		c-2.435379-2.8605957-2.1261215-6.3397064,0.9277649-9.8188171c3.0538864,1.1983643,5.9917984,2.5513535,9.0070267,3.8270187
		c3.0152283,1.2756729,5.9531403,2.783287,9.0456848,3.6337357c3.7883606,1.0823898,7.4994125-0.1159744,11.1331482-1.2756729
		l3.2085114-1.0050812c1.0437317,4.4455261,2.0488129,8.6591187,2.9379196,12.8727036
		c0.0773163,0.5411987-0.1159744,1.0823975-0.4638824,1.5076141c-0.9664154,1.121048-2.0488052,2.1261215-3.2085114,3.0152283
		c-3.5177689,2.3580704-5.6825409,5.6438904-6.8809052,9.6641998c-0.9277573,3.0538788-0.966423,2.9765778-4.2135849,2.8219299
		c-2.1261215-0.1159668-4.2909088-0.3865662-6.3397141-0.0386505
		C94.4205475,136.5478058,92.6036682,137.7075195,90.5162048,138.5193176z"/>
                    <path fill="#FC7F86" d="M147.2643433,80.5728226c13.0659943-0.7344818,25.4361572,9.6255341,27.0597382,22.5755539
		c1.5076141,11.9449387-3.7110443,21.995697-14.187027,27.8715363c-1.6622467,0.9277496-3.0152283,2.5126801-4.1749268,4.05896
		c-1.2756805,1.6622314-2.1647949,3.633728-3.3631439,5.4892578c-1.9328461-3.4404449-3.9816437-6.3783569-7.6927032-8.3885193
		c-2.937912-1.5849304-5.2186584-4.4068604-7.7313538-6.8035889c-0.4638824-0.4252243-0.502533-1.3529892-0.5798492-2.0101471
		c0.4638824,0.2319412,0.7731323,0.4638748,1.121048,0.6571579c9.3162842,5.6052322,21.4544983,2.590004,27.0597382-6.7649307
		c5.6052246-9.3549423,2.5900116-21.4545135-6.7649384-27.0597458c-0.502533-0.3092499-1.0437317-0.5798492-1.5462646-0.8504486
		c-6.9195709-3.3244781-13.607193-2.4353714-20.101532,1.4689636c0.4252319-0.966423,0.8117981-1.9328384,1.4689636-2.7059784
		C140.5767365,85.2116318,143.553299,82.4283447,147.2643433,80.5728226z"/>
                    <path fill="#00B965" d="M146.684494,156.0694885c0.0773163,1.5849304,0.9664154,3.053894,2.3967285,3.7883606
		c2.1261139,1.2756653,4.3295593,2.435379,6.4556885,3.7883606c4.0976105,2.5900116,7.2674713,5.991806,8.929718,10.7852325
		c-0.3865662,3.2471771-0.5411987,6.5330048-1.4303131,9.5868835c-0.8504486,2.9765778-2.3967285,5.7598572-3.8270111,8.5431519
		c-0.3865662,0.8117981-1.3143311,1.4302979-2.1261292,1.9328308c-2.5126953,1.5076141-3.9816437,0.9277649-4.5614929-1.9328308
		c-0.618515-3.1312103-1.0437317-6.3397064-1.5076141-9.5095673c-1.1983643-8.3885345-6.3397064-13.8391266-13.4525452-17.7434692
		c-5.991806-3.2858124-5.0253906-2.0874634-5.0640411-8.8910522c0-0.2705994,0-0.502533,0-0.7731323
		c0.1546173-5.2186584,1.7782135-6.7649384,6.9582214-6.6876221c1.1210327,0,1.7782135,0.3479156,2.2420807,1.3529968
		c0.4638824,1.1210327,1.0823822,2.2034302,1.8168793,3.1698608
		C144.4424133,154.4072571,145.563446,155.1803894,146.684494,156.0694885z"/>
                    <path fill="#FC646E" d="M147.0710602,80.5341644c-3.5177612,1.8941803-6.4943237,4.6388092-9.2389679,7.6926956
		c-0.6571655,0.7344818-1.0823822,1.7395554-1.5462646,2.8219452c-6.6876221,5.2959824-9.5868835,13.2206192-7.5380707,21.145256
		c1.1983643,4.677475,3.9816437,8.2725449,7.692688,11.2104645c0.2319489,0.6958237,0.2706146,1.5849304,0.695816,2.0488129
		c2.4740448,2.3580627,4.7547913,5.2186584,7.731369,6.8035889c3.7110443,1.9714966,5.7598419,4.9480591,7.615387,8.4658356
		c-1.4303131,2.8219452-2.9379272,5.5665741-4.6001587,8.5818024c-2.6286621-4.6388092-5.1413422-8.929718-7.4994049-13.3365784
		c-1.23703-2.3194122-3.1698608-4.252243-5.4892731-5.4506073c-10.5146408-5.6825485-15.8879395-18.014061-13.0659943-29.5724335
		C124.6501312,89.1932831,135.0101471,80.8047562,147.0710602,80.5341644z"/>
                    <path fill="#42CA8D" d="M109.1487656,186.6083527c-0.5411911-3.5177765-1.9714966-6.3010559-5.1800079-8.3498688
		c-5.5665665-3.556427-6.7649307-8.6204529-3.7497025-14.4962921c0.9664154-1.8941803,1.7008972-3.7497101,1.3916473-6.0304565
		c3.5564194-0.6571808,3.4404526-0.618515,4.9094086,2.6673126c1.1983643,2.6673126,2.590004,5.2573242,4.1749344,7.7313538
		c0.6958237,1.0823822,1.9714966,1.8168793,3.0925369,2.5126953c2.435379,1.5462646,3.4018021,3.4791107,2.783287,6.3010406
		C115.6431046,181.2737122,113.2850418,184.5595398,109.1487656,186.6083527z"/>
                    <path fill="#23A6FC" d="M190.5212708,93.6774597v4.0589676c-1.9328308,0.2319412-3.7883606,0.3092499-5.5665741,0.6571579
		c-1.7395477,0.3479156-3.4017792,1.0437317-5.0640411,1.5462723c-1.0823822-2.6673126-2.0101471-5.4892578-3.4017792-8.0792618
		c-1.3916473-2.590004-3.2471771-4.9094086-4.9094238-7.3447876h18.9418182V93.6774597z"/>
                    <path fill="#3F3A3A" d="M136.5950775,123.4431763c-3.8656769-2.9765701-6.6103058-6.5716476-7.80867-11.2491226
		c-2.0488129-7.9246368,0.8117981-15.8879318,7.615387-21.0679398c6.4556885-4.0976181,13.1432953-4.9867249,20.0628662-1.6622391
		c9.7801514,4.7934341,13.8391113,16.5837555,9.0456696,26.3639145c-4.7934265,9.780159-16.5837402,13.8391266-26.3639069,9.0456848
		c-0.4638824-0.2319412-0.9664154-0.5025406-1.4302979-0.77314
		C137.3682098,123.9070663,137.05896,123.7137833,136.5950775,123.4431763 M150.6661377,120.8145218
		c1.8941803-0.8504486,4.0203094-1.4302979,5.6825562-2.6286621c4.5228424-3.2471619,6.4556885-9.2003098,4.9867096-14.5349426
		c-1.4689636-5.3346252-5.9531403-9.432251-11.249115-10.2053833c-9.5868835-1.1983643-17.0089874,6.0304565-16.0039062,15.6559906
		C134.8941803,116.7942123,143.2053833,122.7473602,150.6661377,120.8145218L150.6661377,120.8145218z"/>
                    <path fill="#FE646E" d="M150.3955536,93.6388092c5.0253754,0.5411911,9.5095673,4.677475,10.9785156,10.0121002
		c1.4689484,5.3346329-0.4638824,11.2877808-4.9867249,14.5349426c-1.6622314,1.1983643-3.7883606,1.7782211-5.875824,2.5126953
		c-0.6958313-0.6571655-1.1597137-1.1983643-1.6622467-1.6622467c-5.9144745-5.527916-6.6103058-14.8442001-1.8555145-21.5318222
		C147.921524,96.1128387,149.2358398,94.9144821,150.3955536,93.6388092z"/>
                    <path fill="#FD4755" d="M150.240921,93.5615005c-1.0050812,1.3529816-2.319397,2.5513458-3.3244781,3.9429855
		c-4.754776,6.6876221-4.05896,16.0039062,1.8555145,21.5318222c0.5411987,0.5411911,1.0437317,1.0823898,1.5076141,1.6622467
		c-7.1515045,2.0488052-15.424057-3.9043427-16.2358398-11.5583725
		C133.077301,99.5146408,140.4994049,92.28582,150.240921,93.5615005z"/>
                </g>
            </svg>
        </SvgIcon>


    )
}