import * as React from 'react';
import { SvgIcon} from "@mui/material";


export default function Museum(props){
    return(


            <SvgIcon {...props}>
                <svg version="1.1" id="Слой_1" x="0px" y="0px"
	 viewBox="0 0 278 278" enableBackground="new 0 0 278 278" >
<g id="XMLID_1_">
	<circle fill="#FFFFFF" cx="139" cy="139" r="138.5"/>
	<path fill="#AA8C8C" d="M139,1c36.8611145,0,71.5159607,14.3545074,97.580719,40.4192657
		C262.6455078,67.4840393,277,102.1388855,277,139s-14.3544922,71.5159607-40.419281,97.580719
		C210.5159607,262.6455078,175.8611145,277,139,277s-71.5159607-14.3544922-97.5807343-40.419281
		C15.3545074,210.5159607,1,175.8611145,1,139s14.3545074-71.5159607,40.4192657-97.5807343S102.1388855,1,139,1 M139,0
		C62.2324219,0,0,62.2324219,0,139s62.2324219,139,139,139s139-62.2324219,139-139S215.7675781,0,139,0L139,0z"/>
</g>
<path fill="#040303" d="M213.4885864,133.4680481c4.0975037-1.9555359,8.4993439-1.5446472,12.6805267-0.9359131
	c6.3155518,0.9169006,8.9406891,9.5608215,4.904068,14.9100189c-0.678894,0.9343567-1.0582733,2.0526733-1.088089,3.2072296
	c-0.0608826,23.8735352-0.0342255,47.750885-0.0798798,71.6282349c0,1.1413574-0.4755707,2.2827301-0.7913361,3.5420227
	c-1.3279114,0.1338806-2.6656342,0.1402435-3.9947662,0.0190277c-0.3750763-0.6195221-0.6909637-1.2729797-0.9435272-1.9517365
	c0.030426-24.4898682,0.030426-48.8009491,0.030426-73.1195984h-5.5280151v2.3511963c0,19.4640656,0,38.9293976,0,58.3959961
	c0,4.1850128,0.0380249,8.3700104,0,12.6615448c-0.2624969,0.6657867-0.4527283,1.2212524-0.6429596,1.7805328
	c-1.2783203,0-2.5566406,0-4.0708466-0.1369629c-0.4869843-1.1413574-0.9511261-2.1571808-0.9587402-3.1691742
	c-0.0608978-8.2406616-0.030426-16.481308-0.0380402-24.7295685c0-0.5326233-0.0608826-1.0652618-0.0874939-1.5218201
	c-23.1811371,16.2492218-46.1301422,16.1389008-69.0563507-0.3804474c0,8.716217,0.0304413,17.4019928-0.034256,26.0839844
	c0,1.2745209-0.456543,2.5452423-0.7038269,3.8045349c-1.2783203,0-2.5566559,0-4.0252228-0.129364
	c-0.4136658-0.5838623-0.740448-1.2246246-0.9701538-1.9022827c0-24.4784546,0-48.7781067,0-73.1043854h-5.6497955
	c0,5.7791138,0,11.4136505,0,17.0443726c0,18.7450104,0.0266418,37.4938202,0,56.3643799
	c-0.2929535,0.6619873-0.5440369,1.20224-0.7951508,1.7424774c-1.3370972,0.0215759-2.6743774-0.0279541-4.0062027-0.1483765
	c-0.4223022-1.1033173-0.8750458-2.0582581-0.8826599-3.0132141c-0.0570755-8.3015137-0.0304413-16.5992432-0.0380402-24.9007568
	c0-0.5250244-0.0608597-1.0538483-0.0874939-1.4571381c-22.9376144,16.1084595-45.7192459,16.089447-68.6758957-0.1027069v1.9022675
	c0,8.1721802,0.0304451,16.3595581-0.0380363,24.5203094c0,1.0652771-0.5478554,2.1305542-0.8408127,3.1958313
	c-1.2783241,0-2.5566444,0-3.9376984-0.1179352c-0.3780861-0.6178741-0.694149-1.271637-0.9435196-1.9517365
	c0.0304413-17.097641,0.0342522-34.0164642,0.0380363-50.9352875c0-2.7430725,0.5288391-3.4659271,3.0436363-4.0746613
	c0.5139313,0.1602631,1.0108871,0.3705139,1.4837837,0.6277466c0.4184914,0.7304688,0.9815788,1.3125763,1.0880966,1.96315
	c0.1826096,1.1413574,0.0836868,2.3321838,0.0989227,3.6790009c0,3.9833527-0.0646744,7.7841034,0.0418434,11.5810394
	c0.0438881,0.9011841,0.406601,1.7574158,1.0234222,2.4158936c8.2710838,7.9667206,18.1324654,12.5207672,29.6146126,13.3425446
	c14.4534683,1.0614624,26.8943481-3.5039978,37.3226013-13.5137634c0.438858-0.4244232,0.7091522-0.9934082,0.76091-1.6017151
	c0.0456543-3.732254,0-7.4683228,0-11.3794098c0-1.7120361-0.0190201-3.2528839,0-4.96492c0-2.2218628,0-4.2687073,0-6.4677277
	c0.0304413-4.8051453,0.0989227-9.431488,0-14.076828c-0.0790482-1.0839844-0.4468689-2.1270294-1.0652695-3.020813
	c-2.1838074-3.309967-2.4387054-6.7987213-0.8712311-10.3065186c1.5218201-3.3974609,4.3980484-4.9154816,8.1189194-4.8432007
	c1.6473694,0.0304413,3.2985382,0.0456543,4.9459076,0c3.9605255-0.1255341,7.0384064,1.4609528,8.4993744,5.1399536
	c1.4609528,3.6790009,1.1984406,7.449295-1.6283569,10.6755524c-0.4618683,0.480957-0.7543945,1.0993805-0.8332062,1.7615204
	c-0.0456543,4.9459076,0,9.8918152,0,15.005127c0,2.2218628,0,4.2648926,0,6.4867554c0,1.7120361,0,3.2528839,0,4.9687347
	c-0.0304413,3.5306244-0.0684814,6.8862305,0,10.2418518c0.0125427,0.7249298,0.2714996,1.4240112,0.7342834,1.9821625
	c17.7558289,18.5966339,49.1890106,18.8325043,67.462265,0.5059967c0.5314331-0.579361,0.8436584-1.3262787,0.8826294-2.1115112
	c0.0798798-3.9871826,0.0342255-7.989563,0.0760803-12.1289062c0-1.3277893-0.0875092-2.4919739-0.1065521-3.8273773
	c0.0570831-7.0460205,0.0798798-13.9284515,0.0532684-20.8070679c0.0069275-0.6095581-0.1810303-1.2053833-0.5364532-1.7006378
	c-4.20784-5.1323242-3.8501892-10.3445587,1.0842896-14.9937134 M225.4652863,144.7561493
	c1.6321716-1.1908264,2.2294617-2.9104767,1.5940857-4.7861176c-0.5935364-1.7615051-2.0050049-2.0544586-3.6485443-1.9973907
	c-1.7653198,0.0570679-3.5382538-0.0456543-5.2997589,0.0456543c-1.9022827,0.0989227-2.7392578,1.2745209-2.7126465,3.5686646
	c0.0228577,2.1191406,0.9169159,3.2909393,2.7621002,3.3365936c2.3321991,0.0570526,4.6681976-0.0266418,7.304718-0.1674042
	 M136.7850494,144.9501801c0.506012,0,1.0158081,0,1.5218201,0c2.1381531-0.0722809,3.0436401-1.1109314,3.0778961-3.4240875
	c0.0342407-2.3131561-0.8712311-3.4469299-2.9979858-3.5077972c-1.9631348-0.0532684-3.9301147-0.0608673-5.8932495,0
	s-2.8724213,1.1413574-2.8800354,3.4240875c-0.0076294,2.2827301,0.8750305,3.4241028,2.8153687,3.5001984
	c1.2897644,0.0494385,2.6213684,0.0037994,4.3181915,0.0037994L136.7850494,144.9501801z"/>
<path fill="#FEFEFE" d="M143.2490234,225.956604c0.0684814-1.2745209,0.5174103-2.5452423,0.5250244-3.8045502
	c0.0646667-8.6819763,0.034256-17.3677521,0.034256-26.0839691c22.926178,16.5003204,45.8752441,16.6106567,69.0563202,0.3804474
	c0.0266113,0.4451447,0.0874939,0.9777679,0.0874939,1.5218201c0,8.2444611-0.0228424,16.4851074,0.0380402,24.7295685
	c0,1.0120087,0.471756,2.0240173,0.7837677,3.1691895C190.3645782,225.956604,166.8942871,225.956604,143.2490234,225.956604z"/>
<path fill="#FEFEFE" d="M57.266243,225.956604c0.1141319-1.0652618,0.6505852-2.1305542,0.6619873-3.195816
	c0.0684776-8.1721802,0.0380363-16.3595581,0.0380363-24.5203094v-1.9022675
	c22.9566536,16.1921539,45.7382622,16.2111664,68.6758881,0.1027222c0.0266342,0.4032745,0.0875015,0.9321136,0.0875015,1.4571381
	c0,8.3015137,0,16.5992432,0.0380325,24.9007568c0,0.9549561,0.4603577,1.9022827,0.7076416,3.0131989
	C104.1306839,225.956604,80.7859573,225.956604,57.266243,225.956604z"/>
<path fill="#050302" d="M38.4451561,137.8699188c2.141964-4.3752289,5.6155167-6.0872803,10.2266273-5.5964966
	c2.7202606,0.2929535,5.4329033,0.6581879,8.3700027,1.1413727c4.510006,3.0456696,5.697094,9.1707764,2.651413,13.6807861
	c-0.1987495,0.2942963-0.413269,0.5776367-0.642601,0.8487854c-0.6306839,0.8128052-0.9971581,1.7993317-1.0500565,2.826767
	c-0.1407661,2.6631775,0.0646706,5.3263702-0.1065407,7.9895477c-0.0684776,1.0919037-0.76091,2.1457672-1.2821312,3.2757111
	c-0.5139503,0.1911926-1.0434837,0.3375854-1.5826797,0.4375305c-2.1724052-0.3500366-2.7849312-1.7310638-2.7849312-3.5762787
	v-8.1455383H46.708622v2.3017426c0,19.6213379,0,39.2426453,0,58.8639679c0,4.0480499,0.0380363,8.0960693,0,12.2468414
	c-0.380455,0.6619873-0.7076378,1.2250671-1.0234222,1.7919464c-1.2316971,0.018631-2.4635048-0.0270996-3.6904182-0.1369629
	c-0.4793739-1.2745209-0.9511375-2.4120789-0.9511375-3.5458374c-0.0405655-23.9229889-0.0595856-47.8460083-0.0570755-71.7689972
	c-0.008522-0.8527374-0.2046471-1.6931305-0.5744896-2.4615326c-0.5953674-1.0779419-1.2520828-2.1208344-1.9669228-3.1235352
	V137.8699188 M43.7981567,140.3847198c-0.0836868,3.8920441,0.5554733,4.5654449,4.3752327,4.5654449
	c1.4533386,0,2.9066772,0.0342407,4.3562088,0c2.0963097-0.0570679,3.0436401-1.1413574,3.0436401-3.4659271
	c0-2.324585-0.9131012-3.4241028-3.0170059-3.4811707c-1.8299866-0.0532684-3.6637802,0.0266418-5.4937668-0.0228119
	C45.4683609,137.9307861,44.3802414,138.5509338,43.7981567,140.3847198z"/>
<path fill="#F1F1F1" d="M38.4451561,145.0985565c0.7499275,0.9127808,1.4051323,1.8994293,1.9555435,2.9447174
	c0.3698425,0.7684021,0.5659676,1.6087952,0.5744896,2.4615326c0.0304184,23.9229889,0.0494614,47.8460083,0.0570793,71.7689972
	c0,1.1413727,0.4717598,2.2827301,0.7799263,3.5458374c-1.1184235,0.1152954-2.2429504,0.1610413-3.3670387,0.1369629V145.0985565z"
	/>
<path fill="#F9F4F4" d="M45.8526001,225.956604c0.1924095-0.6705017,0.5449524-1.2841797,1.0272331-1.788147
	c1.9327164-0.095108,3.6181259-0.0989227,5.2997322-0.1027069c0.3166656,0.5664368,0.582756,1.1596985,0.7951584,1.7729187
	C50.619709,225.956604,48.3369789,225.956604,45.8526001,225.956604z"/>
<path fill="#F9F4F4" d="M218.1986084,225.956604c0.0342255-0.6433563,0.2593842-1.2619171,0.646759-1.7767334
	c1.9364929-0.1065216,3.621933-0.1103363,5.3035126-0.1141205c0.319809,0.5648651,0.5860138,1.158432,0.7951355,1.7729187
	C222.7032318,225.956604,220.5384216,225.956604,218.1986084,225.956604z"/>
<path fill="#FCF7F7" d="M138.0558319,224.0885773c0.2663116,0.536438,0.5326385,1.072876,0.8141785,1.7386627
	c-2.2827301,0.129364-4.5654602,0.129364-7.0346069,0.129364c0.1171417-0.6361542,0.3874359-1.2342072,0.7875366-1.7424774
	C134.5746613,224.0885773,136.3133392,224.0885773,138.0558319,224.0885773z"/>
<path fill="#020202" d="M57.0874405,127.1410904c-1.87183-0.3424072-2.6365509-1.5218201-2.6365509-3.3632202
	c0-17.6175919,0-35.2377243,0-52.8603897c0-3.6637726,2.6023216-6.5247955,6.2128334-6.1861954
	c7.0155907,0.6734085,13.2093773-1.4190941,19.4716759-4.2725067c14.2023773-6.4487076,28.5835648-12.5055466,42.8582382-18.7982674
	c0.8366776-0.4525833,1.4773026-1.1975098,1.7995453-2.0925026c1.5524902-4.4345856,5.6856384-7.4469833,10.3825989-7.5672455
	c4.6872101-0.0570679,8.9711304,3.0930977,10.5728302,7.5786591c0.3301086,0.8936996,0.9792633,1.6336365,1.8223724,2.077282
	c17.227005,7.5710526,34.4743042,15.0913734,51.7418518,22.5609703c0.6969604,0.2784805,1.440918,0.4205551,2.1914215,0.4185028
	c2.8495941,0.0494537,5.7068176,0,8.556427,0.0266266c3.8806152,0.0342407,6.3916473,2.5490494,6.395462,6.4677353
	c0.0152283,17.5009155,0.0152283,34.9942322,0,52.4799271c0,1.9022751-0.646759,3.1996307-2.9447174,3.5115967
	c-2.2827301-0.4146881-2.7887115-1.7957458-2.7849121-3.6561661c0.0228119-16.9200974,0.0228119-33.8401871,0-50.7602768v-2.3550186
	H77.4455795c-0.6924286,0-1.3886719,0.0418549-2.2827301,0.0342407c-5.0866776-0.0266266-9.9983597-0.0266266-15.0317726-0.0266266
	v2.355011c0,16.9150238,0,33.8287735,0,50.7412491c0,2.5300217-0.6848106,3.3213654-3.0702705,3.6675873 M169.8922729,64.6437836
	h15.3437347l0.0798798-0.30056L145.569809,47.0667725c-5.2883301,9.3059196-14.5866394,9.2640724-20.6777039,0.0304375
	L85.3248062,64.3394165l0.1217499,0.3157806h84.4457169 M133.4751587,47.915184
	c3.2186584,0.8750458,6.1557617-0.6543808,6.882431-3.5838814c0.7433777-2.8027382-0.9260559-5.677433-3.7287903-6.4208107
	c-2.6155396-0.6937218-5.329895,0.7140503-6.2695618,3.2516212c-0.9853973,2.6099205,0.0418396,5.0105896,3.115921,6.7606812
	V47.915184z"/>
<path fill="#C58C79" d="M75.3492661,70.4343033c0.6962433-0.0228271,1.3924561-0.0646744,2.0848846-0.0646744h133.3570251v2.3359909
	c0,16.9226303,0,33.8427124,0,50.7602768c0,1.8604202,0.4869995,3.241478,2.6631775,3.6942139
	c0.1369629,2.0202179,0.1331482,3.9909668,0.1027374,6.136734c-4.9459076,4.824173-5.3263702,10.0364075-1.1033325,15.1687317
	c0.355423,0.4952545,0.5433807,1.0910797,0.5364532,1.7006378c0.0266113,6.8786163,0,13.7610474-0.2130737,20.8184814
	c-23.0897827,0.1788177-45.9665222,0.1788177-68.8623199,0.1826324c0-2.0468597-0.0380249-4.0898895,0.144577-6.3079376
	c1.8164062-0.1724548,3.6443024-0.1877289,5.4633331-0.0456543c0.4108887,2.0468445,1.6740112,2.6631775,3.4849548,2.6631775
	c16.2149506-0.0202789,32.4286804-0.0266266,48.6411591-0.0190125c2.4425354,0,3.4469299-1.0310364,3.4469299-3.4887848
	c0-28.1232147,0-56.245163,0-84.3658371c0-2.5148087-1.0500488-3.5077972-3.6295471-3.5192108
	c-3.8654327-0.015213-7.7295685-0.015213-11.592453,0c-2.3359833,0-3.1045227,0.6772156-3.7588806,2.9903793
	c-2.206665,0-4.2497101,0.0304337-6.3041229-0.0989227c-0.5897217-2.4311066-1.2555237-2.895256-4.0100098-2.895256H77.8260345
	c-0.8306885,0.0177689-1.660614-0.0613937-2.4729538-0.2358856C75.3492661,73.9154663,75.3492661,72.1729813,75.3492661,70.4343033z
	"/>
<path fill="#B97C64" d="M75.1818695,70.4038696c0.1750412,1.8130875,0.1750412,3.6388245,0,5.4519119
	c-2.0734863,0.205452-3.9757614,0.1940384-5.8742218,0.220665c-2.408287,0.0342407-3.4431152,1.06147-3.4431152,3.5115967
	c0,28.1156158,0,56.2324829,0,84.3506317c0,2.4691467,0.996788,3.4697418,3.4240952,3.507782
	c3.2909393,0.0456543,6.5818634,0.0608826,9.8918228,0.2130585c2.1419601,2.8267822,5.2008133,3.4507294,8.3129272,3.4697418
	c13.0534058,0.0913086,26.1068039,0.0342407,39.1602097,0.0342407c0.0541992,1.5940704,0.0097275,3.1899414-0.1331482,4.7785187
	c-1.8214874,0.1710358-3.6540985,0.188858-5.4785614,0.0532684c-0.6619873-2.3359985-1.358223-2.8381958-3.8996582-2.8381958
	c-16.5421906,0-33.0830841,0-49.6227188,0c-2.4957809,0-3.1730042,0.521225-4.1165199,2.9447174
	c-1.9251022-0.3804474-3.6789932-0.76091-5.4329071-1.1413574c-0.0190163-1.1679993,0.0799065-2.3550262-0.1027298-3.5001984
	c-0.1065369-0.6505737-0.6696053-1.232666-0.9435196-2.0620575c0-2.6251373-0.0989227-5.033432-0.1864204-7.4416962
	c0.4032822-1.0728912,1.0842896-2.1267395,1.1413651-3.2186432c0.1712112-2.6631927-0.0342522-5.3263702,0.1065407-7.989563
	c0.0529175-1.02742,0.4193726-2.0139618,1.0500565-2.826767c3.5221786-4.1540833,3.0098991-10.3769226-1.1441727-13.8990936
	c-0.2325363-0.1971436-0.4740601-0.3834229-0.7238503-0.558197c-0.0722847-2.0582581-0.0684776-4.0252075-0.0874977-6.1633606
	c2.3664207-0.5174179,3.0436401-1.3087616,3.0436401-3.8387833c0-16.9099503,0-33.8237,0-50.7412567v-2.355011
	C65.1835251,70.3772354,70.095192,70.3772354,75.1818695,70.4038696z"/>
<path fill="#57BD91" d="M149.4580383,164.6691437h-5.4062653c-0.1940308-4.9459076-0.2472992-9.8918152-0.2016449-14.837738
	c0.0788116-0.6621399,0.3713226-1.2805481,0.8331909-1.7615051c2.8267822-3.2262573,3.1121216-6.9394836,1.6283569-10.6755524
	s-4.5388184-5.2655029-8.4993744-5.1399536c-1.6473694,0.049469-3.2985382,0.0342407-4.9459076,0
	c-3.7208557-0.0722809-6.6008835,1.4457245-8.1189117,4.8432007c-1.5674744,3.507782-1.3125763,6.9965668,0.8712311,10.3065186
	c0.6184006,0.8937683,0.9862213,1.9368286,1.0652695,3.020813c0.1065369,4.6263275,0.0380325,9.252655-0.1674042,14.0768127
	c-1.8155441,0.1676331-3.6422043,0.1778107-5.4595108,0.0304413c-0.7228775-2.3169708-1.3962631-2.7849274-3.9605255-2.7849274
	c-10.83535,0-21.6719666,0-32.5098648,0h-2.2827301c0.76091-0.7989655,1.1413651-1.2212677,1.5218201-1.6283569
	c11.885376-12.2176666,23.7733612-24.4328003,35.663826-36.645401c9.4771118-9.7053986,22.4468231-9.7092056,31.935379-0.0380478
	c4.8850403,4.9687424,9.724411,9.9869308,14.5980682,14.9708939c1.7424622,1.7843323,2.7621002,1.8528137,4.8051453,0.5516663
	c1.4457397,1.5979156,2.8686371,3.0702667,4.2268372,4.6643829c-1.3201599,1.7310638-0.9358978,3.1501617,0.3804626,4.5007782
	c2.8432922,2.8838348,5.6750946,5.7790985,8.4955444,8.6857758c1.4761658,1.5218201,2.9294739,3.0626526,4.7176361,4.9459076
	h-2.4577179c-10.9494934,0-21.8990021,0-32.8484497,0C150.7744293,161.7472534,150.0249481,162.3331451,149.4580383,164.6691437z"/>
<path fill="#FDFDFD" d="M212.9483643,174.9756622c0,3.9871674,0.0494537,7.9895477-0.030426,11.9653168
	c-0.0389557,0.7852325-0.3511963,1.5321503-0.8826294,2.1115112c-18.2618256,18.3265076-49.7064209,18.0906219-67.4622498-0.5059967
	c-0.4627686-0.5581512-0.7217255-1.2572327-0.7342682-1.9821625c-0.0684814-3.3556213-0.0304413-6.7112274,0.1750183-10.2532654
	c1.8245087-0.1697083,3.6605072-0.1760712,5.486145-0.0190125c0.4489441,2.1609802,1.8528137,2.594696,3.6980133,2.5908966
	c16.7679291-0.0304413,33.5332947-0.0304413,50.2960968,0c2.1001282,0,3.3784485-0.7228699,4.0176239-2.7811279
	C209.4367218,175.7365723,211.1944733,175.3561249,212.9483643,174.9756622z"/>
<path fill="#FDFDFD" d="M57.9700966,175.1468811c1.8558083,0.2354431,3.693737,0.5951385,5.5013847,1.0766754
	c0.6277618,1.9745636,1.8147812,2.6631775,3.6599731,2.6631775c16.83638-0.0304413,33.6689682-0.0304413,50.497757,0
	c1.9479218,0,3.1235428-0.76091,3.4240875-2.7316589c1.7500839,0,3.5001907-0.0190125,5.425293,0
	c0.1940384,3.7436829,0.2473068,7.4797516,0.2016525,11.2120056c-0.0517578,0.6083069-0.322052,1.1772919-0.76091,1.6017151
	c-10.3978348,9.9755249-22.8387146,14.5409851-37.2541504,13.5175476
	c-11.4821167-0.8217773-21.3434982-5.375824-29.6145859-13.3425446c-0.6168213-0.6584778-0.9795341-1.5147095-1.0234222-2.4158936
	C57.905426,182.9309692,57.9662895,179.1302338,57.9700966,175.1468811z"/>
<path fill="#EA5F5F" d="M138.0748444,223.9097595c-1.8004303,0.1513824-3.6073761,0.2110596-5.4138641,0.1788177
	c-0.1902313-18.7450104-0.2054291-37.4938354-0.2168579-56.2388306c0-5.630722,0-11.265274,0-17.0443726h5.6535492
	C138.0938568,175.1316528,138.0938568,199.4313049,138.0748444,223.9097595z"/>
<path fill="#EE6161" d="M52.2061996,223.8869324c-1.7467155,0.156601-3.5007019,0.2175751-5.2540817,0.1826172
	c-0.2016525-4.0556488-0.2396851-8.1036835-0.2396851-12.1517181c0-19.6238556,0-39.2451782,0-58.8639679v-2.3017426h5.5356102
	v8.1303101c0,1.8452148,0.6125259,3.2262573,2.7849312,3.7512817c0.1369591,2.2028351,0.1369591,4.2040253,0.1407661,6.2090302
	c-2.3968582,0.6429596-2.9218903,1.3658295-2.9256973,4.108902
	C52.2404518,189.8704681,52.2366409,206.7892914,52.2061996,223.8869324z"/>
<path fill="#EE6161" d="M224.1755829,223.8869324c-1.7467804,0.1560211-3.5006714,0.2182465-5.2541046,0.1864166
	c-0.2016449-4.1849976-0.2396851-8.3700104-0.2396851-12.5550079c0-19.4640656,0-38.9293976,0-58.3959961v-2.3512115h5.5280151
	C224.2060089,175.0859985,224.2060089,199.3970642,224.1755829,223.8869324z"/>
<path fill="#C68C79" d="M121.0837479,164.6691437h5.4062653c0.1902313,2.043045,0.2092514,4.0898895,0.1978455,6.3117523
	c-13.0851212,0.1750031-26.1473923,0.2219238-39.1868362,0.1407623c-3.1121216-0.0190125-6.1709671-0.6429749-8.1379395-3.5039825
	c12.7452393-0.1559906,25.3420868-0.1598053,37.9351578-0.1750031
	C119.8130188,167.4502716,120.455986,166.9556885,121.0837479,164.6691437z"/>
<path fill="#B77B63" d="M149.4694672,176.1398621c-1.7500763,0-3.5039978-0.0228271-5.4290924-0.0190277
	c-0.1657715-1.5883789-0.2293091-3.1857758-0.1902313-4.7823181c22.9109955-0.1788177,45.7877502-0.1788177,68.828064-0.1902313
	c0.2163239,1.20401,0.3220367,2.425293,0.3157654,3.6485596c-1.8057709,0.5487518-3.6551971,0.9420624-5.5280151,1.1756134
	c-0.6011047-1.9517365-1.7386627-2.8191681-3.5839081-2.8191681c-16.9936371,0-33.9873047,0-50.9809418,0
	C150.7211609,173.1685028,149.9412231,173.9027863,149.4694672,176.1398621z"/>
<path fill="#FCE259" d="M225.2750549,144.8094025c-2.484375,0.0836945-4.8203735,0.1674042-7.1525574,0.1103363
	c-1.8451996-0.0456543-2.7392578-1.217453-2.7621155-3.3365784c-0.0266113-2.2827301,0.810379-3.4697571,2.7126465-3.5686798
	c1.7615051-0.0912933,3.5344391,0,5.2997589-0.045639c1.6435394-0.0570679,3.0436401,0.2358704,3.6485443,1.9973755
	C227.6567078,141.8418579,227.0594177,143.5615082,225.2750549,144.8094025z"/>
<path fill="#FCE259" d="M136.5644531,144.9463806c-1.5218201,0-2.8457947,0.045639-4.1849976,0
	c-1.9403229-0.0913086-2.8229675-1.2250671-2.8153839-3.5001984c0.0075989-2.275116,0.8674469-3.3556061,2.8800507-3.4240875
	c2.0125885-0.0684814,3.9300995-0.0608826,5.8932343,0c2.1267548,0.0608673,3.0093842,1.1413574,2.9979858,3.5077972
	c-0.0113983,2.3664246-0.9397125,3.3670197-3.0778809,3.4240875
	C137.7628632,144.9615784,137.253067,144.9463806,136.5644531,144.9463806z"/>
<path fill="#FDFDFD" d="M55.2992973,168.888382c-0.1331482-2.0392303-0.1331482-4.0404205-0.1331482-6.2128143
	c0.4485397-0.2826385,0.9354401-0.4991913,1.4457207-0.6429749c0.2225533,2.415451,0.3076591,4.8415985,0.2548981,7.2666931
	C56.329525,169.226532,55.8031502,169.088562,55.2992973,168.888382z"/>
<path fill="#FCE259" d="M43.824791,140.2249298c0.5554733-1.673996,1.6435699-2.2827301,3.2262497-2.2560883
	c1.8299866,0.0494537,3.6637802-0.0304413,5.4937668,0.0228119c2.1039276,0.0646973,3.020813,1.1413727,3.0170059,3.4811707
	c-0.0038071,2.3397827-0.9359283,3.4240875-3.0436401,3.4659271c-1.4495316,0.0418549-2.9028702,0-4.3562088,0
	C44.3536301,144.9463806,43.714447,144.2767639,43.824791,140.2249298z"/>
<path fill="#FEFEFE" d="M169.7020569,64.6437836H85.4579544l-0.1217499-0.3157806l39.5672989-17.2422104
	c6.0872803,9.2336388,15.3894043,9.275486,20.6777039-0.0304375l39.7194977,17.2992821l-0.079895,0.30056L169.7020569,64.6437836z"
	/>
<path fill="#F9E058" d="M133.3343658,47.8809433c-2.9333038-1.7082405-3.9491119-4.1089096-2.9751587-6.7112198
	c1.0069122-2.7192039,4.0275421-4.1072884,6.7467346-3.1003685c2.5375824,0.9396591,3.9453583,3.6540298,3.2516327,6.2695541
	C139.6461182,47.2684135,136.6937866,48.7978401,133.3343658,47.8809433z"/>
<path fill="#98E5FB" d="M175.1044922,143.4930267c-1.4190826-1.4685364-2.84198-2.940918-4.2344666-4.6643677
	c1.0196381-1.9251099,0.3804626-3.214859-0.8369751-4.4741516c-4.9459229-5.0106049-9.808136-10.0744476-14.7312012-15.0964508
	c-11.4707184-11.7180023-28.2107315-11.7218018-39.6586227-0.0190125
	c-6.1126404,6.2496033-12.2151489,12.5080872-18.3074875,18.7754364
	c-7.2286377,7.3808289-14.4344559,14.7502289-21.7543945,22.0055084c-0.1364288-0.5310974-0.2104797-1.0762939-0.2206726-1.6245422
	c0-25.5171051,0-51.0341949,0.1788025-76.5779343c7.3351746-0.0342331,14.4991226-0.0380478,21.6592865-0.0380478h78.7084808
	c2.6213379,0,3.2300568-0.4261017,3.8844299-2.6631775c2.0392303-0.0228271,4.0822754-0.0456543,6.3345795,0.0875015
	c0.5859222,1.9669571,1.7957458,2.5947037,3.5306396,2.5832901c3.2034454-0.0266342,6.4068451,0,9.5684357,0v79.8308258
	c-1.79953,0.4831696-2.937088-0.2016449-4.093689-1.4190979c-5.1057281-5.3682098-10.3369751-10.6184998-15.4692993-15.9791107
	c-1.3924866-1.4533386-2.8268127-1.7881317-4.5654602-0.7608948 M77.5520935,106.5622864
	c-0.380455,5.2160339,2.6860123,9.3325577,7.7688828,9.5608292c6.1987839,0.3044205,12.4096603,0.2663116,18.604248-0.1141357
	c6.3916321-0.4185028,11.0331879-5.661171,11.6038666-12.2696686c0.506012-5.8628082-3.5192032-11.6305008-9.3667908-13.4262466
	s-12.4941483,0.6353607-15.2524414,6.1519547c-1.1413651,2.3055573-2.4805756,2.7621002-4.7823257,2.7240601
	c-4.5007858-0.0722885-7.5710526,2.6213303-8.5754395,7.3732147 M184.7147827,97.0509262
	c-3.0816803-5.4595337-8.9825287-7.5291977-14.3735962-5.0448303c-5.4911804,2.5479431-7.8771973,9.0649567-5.3292389,14.55616
	c2.4177856,5.2107239,8.4483032,7.6708984,13.8210144,5.6383743c5.9236603-2.2827301,8.4841614-8.6020813,5.8818207-15.1497116
	V97.0509262z"/>
<path fill="#010202" d="M75.6840744,160.1379242c7.2184906-7.3732147,14.4357071-14.7476959,21.6516647-22.1234436
	c6.1075745-6.2496033,12.2100601-12.5080795,18.3074875-18.7754364
	c11.4554825-11.7027893,28.1726608-11.6989899,39.6585922,0.0190201
	c4.9230957,5.0220032,9.8005066,10.0858536,14.7312317,15.0964432c1.2364807,1.2593079,1.8566132,2.549057,0.8103638,4.3448029
	c-2.0696564,1.5560608-3.0892944,1.4875793-4.8317566-0.2967529c-4.873642-4.983963-9.7130127-10.0021515-14.598053-14.9708939
	c-9.5113678-9.6711655-22.4468231-9.6673584-31.9353638,0.0380478
	c-11.9184036,12.1973724-23.8063354,24.4124908-35.6638565,36.6453857c-0.380455,0.4070892-0.76091,0.8293915-1.5218124,1.6283569
	h2.2827225c10.8404312,0,21.6770325,0,32.5098648,0c2.5642624,0,3.2376785,0.4679565,3.9605255,2.7849274
	c-0.6011276,2.4234924-1.2440948,2.9180908-3.7627029,2.9180908c-12.5930405,0-25.1899185,0-37.9579773,0.0570679
	c-3.4659424,0-6.7568817,0-10.0478058-0.0608673c-2.4311066-0.0380554-3.4240875-1.0386505-3.4240875-3.5077972
	c0-28.1156158,0-56.2324829,0-84.3506317c0-2.4501343,1.0348206-3.477356,3.4431076-3.5115967
	c1.9022751-0.0266342,3.8045502,0,5.8742218-0.0456543c1.0006027,0,1.8223724,0.0342407,2.6631851,0.0342407h97.9404678
	c2.7544861,0,3.4240875,0.4641495,4.0100098,2.895256c-0.6391907,2.3854523-1.2479095,2.8115692-3.8692474,2.8115692H97.1987839
	H75.3644791h-3.6713943v79.8916855C74.2649841,161.8309631,74.2649841,161.8309631,75.6840744,160.1379242z"/>
<path fill="#010202" d="M175.0284119,143.618576c1.7919312-1.1679993,3.2262573-0.8331909,4.6187286,0.6201477
	c5.1323395,5.3263702,10.3635864,10.5918579,15.4693146,15.9790955c1.1413574,1.2174683,2.2827301,1.9022827,4.093689,1.4190979
	v-79.872673c-3.1616058,0-6.3650055,0-9.5684509,0c-1.7348938,0-2.9447021-0.616333-3.3670044-2.5642624
	c0.4451447-2.4729538,1.213623-3.1387482,3.5496216-3.1501617c3.865387-0.0152206,7.7295685-0.0152206,11.592453,0
	c2.579483,0,3.6295471,1.0043945,3.6295471,3.5192108c0,28.1232147,0,56.245163,0,84.3658447
	c0,2.4577332-1.0044098,3.4849701-3.4469452,3.4887695c-16.2150269,0.0152283-32.4287109,0.0215607-48.6411285,0.0190277
	c-1.8109741,0-3.0740814-0.6087341-3.4849548-2.6631927c0.5364227-2.4767609,1.2859344-3.0436401,3.8540039-3.0436401
	c10.9494781,0.0000153,21.8989716,0.0000153,32.8484802,0h2.4577179c-1.7881165-1.8756409-3.2414856-3.4240875-4.7176514-4.9459076
	c-2.8229828-2.9016113-5.6548004-5.7968597-8.4955292-8.6857758
	C174.1076965,146.7687378,173.7234344,145.3496552,175.0284119,143.618576z"/>
<path fill="#040302" d="M121.0456848,175.995285c-0.2929535,2.0963135-1.4685516,2.8724213-3.4240952,2.8724213
	c-16.831337-0.0278931-33.6639023-0.0278931-50.4977493,0c-1.8451996,0-3.0436401-0.6696014-3.4887657-2.6631775
	c0.7076378-2.5414429,1.384861-3.0436401,3.8806419-3.0436401c16.5421448,0,33.0830765,0,49.6227112,0
	C119.6874619,173.1570892,120.3836975,173.6593018,121.0456848,175.995285z"/>
<path fill="#040302" d="M149.4999084,176.276825c0.4413147-2.3740387,1.2212677-3.1083221,3.4241028-3.1121216
	c16.9936676-0.0202942,33.9873047-0.0202942,50.9809418,0c1.8451843,0,2.9827423,0.8674469,3.4240875,2.8153687
	c-0.4223022,2.1876221-1.7006226,2.9180908-3.8045502,2.9104767c-16.7653656-0.0532532-33.5307312-0.0532532-50.2961273,0
	C151.3527222,178.871521,149.9488525,178.4378052,149.4999084,176.276825z"/>
<path fill="#030404" d="M77.5711365,106.4101028c0.9853897-4.5996933,4.0556335-7.2933121,8.5564194-7.2286377
	c2.2827301,0.0380478,3.6295319-0.4185028,4.7823257-2.7240601c2.7582932-5.5165939,9.3591766-7.9629135,15.2524414-6.151947
	c5.8932648,1.8109589,9.8918228,7.5634384,9.3667908,13.4262466c-0.5668716,6.6084976-5.2122345,11.8511581-11.6038666,12.2696609
	c-6.1945801,0.380455-12.4054642,0.4185562-18.604248,0.1141434c-5.0828705-0.2282791-8.1531448-4.3447952-7.7498627-9.7130127
	 M84.0693054,109.7276764c0.8826599,0.2701187,1.7615128,0.7609024,2.6631851,0.7609024
	c5.0638504,0.0760956,10.1353226,0.1027298,15.2181931,0.0190277c5.5698624-0.0875015,9.2032013-4.9154739,7.5558243-9.8918228
	c-0.9817657-2.906517-3.6129456-4.9391556-6.6731873-5.1551666c-3.2757111-0.1788101-6.1253128,1.4457321-7.3884277,4.5654602
	c-1.4038773,3.4811554-3.90728,4.9459076-7.5368118,4.8051453c-0.4413147,0-0.8864594,0-1.3315887,0
	c-3.0474472,0.079895-4.1545563,1.9707489-2.5072098,4.9040604L84.0693054,109.7276764z"/>
<path fill="#61D9F9" d="M75.5813446,160.019989c-1.3163834,1.8109741-1.3163834,1.8109741-3.888237,1.6435699V81.7870712
	c1.0919037,0,2.2104416,0,3.496376,0.0304413c0.1712112,25.5437317,0.1674042,51.0608292,0.1712112,76.5779343
	C75.3708878,158.9436951,75.4449387,159.4888916,75.5813446,160.019989z"/>
<path fill="#030404" d="M184.7870941,97.1764679c2.5300293,6.422081-0.030426,12.7528458-5.9388885,15.0165482
	c-5.6632233,2.1385345-11.9877625-0.7187881-14.1263428-6.3820038c-2.0252075-5.3630905,0.4243927-11.3808441,5.6193695-13.804924
	c5.3910675-2.4843674,11.2919159-0.4146957,14.4572906,5.1703796 M180.1759796,102.0767288
	c0.114151-3.4887695-3.1539764-5.7943268-5.9959717-5.3263702c-2.6951752,0.3976517-4.6392975,2.7894211-4.477951,5.5089874
	c0.1794891,2.6291428,2.2546692,4.7317886,4.8812103,4.9459076c2.7735291,0.2130585,4.9839935-1.7158432,5.5812988-5.1285248
	H180.1759796z"/>
<path fill="#DBF1F8" d="M83.9551544,109.6249466c-1.5218201-2.8229752-0.4261093-4.713829,2.6213379-4.778511
	c0.4451294,0,0.890274,0,1.3315887,0c3.6295319,0.1521835,6.1329346-1.3239822,7.5368118-4.8051453
	c1.2631149-3.1197281,4.1127167-4.7442703,7.3884277-4.5654526c3.0483932,0.2298813,5.6632309,2.2618027,6.6389389,5.1589661
	c1.647377,4.9839554-1.9859619,9.8119202-7.5558243,9.8918228c-5.063858,0.0836945-10.1353226,0.0570602-15.2181931-0.0190277
	C85.8307953,110.5037994,84.9519424,110.0054016,83.9551544,109.6249466z"/>
<path fill="#FAE058" d="M180.1455231,102.2212982c-0.5783081,3.2681122-2.7887268,5.1970139-5.5660553,4.9877625
	c-2.6265411-0.214119-4.7017212-2.3167648-4.8812256-4.9459076c-0.1659851-2.8843689,2.0376587-5.3571854,4.9220428-5.5231781
	c2.8843842-0.1660004,5.3572083,2.0376663,5.5231934,4.9220428c0.0107727,0.1875229,0.0114594,0.3754883,0.0020447,0.5630875
	V102.2212982z"/>
</svg>
            </SvgIcon>


)
}